<div class="d-flex flex-row" style="padding-top: 52px;" *ngIf="!isLoading() else loading">
    <div class="d-flex flex-column" style="width: 292px; margin-left: 52px;">
        <div class="title">{{ buyer.user.fullName }}</div>
        <div class="subtitle">{{ buyer.user.email }}</div>
        <div class="d-flex flex-row" style="gap: 6px">
            <div *ngIf="buyer.trusted" class="badge-narrow badge-narrow-gold" style="margin-top: 12px">
                TRUSTED
            </div>
            <div *ngIf="buyer.blocked" class="badge-narrow badge-narrow-error" style="margin-top: 12px">
                BLOCKED
            </div>
        </div>
        <div class="section" style="margin-top: 50px">
            <div class="header">Insights</div>
            <div class="break"></div>
            <div class="d-flex flex-row">
                <div class="flex-even d-flex flex-column label" style="margin-top: 0;">
                    <div class="name">Since</div>
                    <div class="value">{{ buyer.user.created | date:'MMM y' }}</div>
                </div>
                <div class="flex-even d-flex flex-column label" style="margin-top: 0;">
                    <div class="name">Purchases</div>
                    <div class="value">{{ buyer.totalPurchaseCount }}</div>
                </div>
                <div class="flex-even d-flex flex-column label" style="margin-top: 0;">
                    <div class="name">LTV</div>
                    <div class="value">{{ buyer.totalPurchaseValueCents / 100 | currency : 'USD' : 'symbol' : '0.0-0' }}</div>
                </div>
            </div>
        </div>
        <div class="section" style="margin-top: 48px">
            <div class="header">
                Details
                <button class="edit-button" (click)="editButtonPressed()">Edit</button>
            </div>
            <div class="break"></div>
            <div class="subsection">
                <div class="header">Account</div>
                <div class="d-flex flex-column label">
                    <div class="name">Name</div>
                    <div class="value">{{ buyer.user.fullName }}</div>
                </div>
                <div class="d-flex flex-column label">
                    <div class="name">Email Address</div>
                    <div class="value">{{ buyer.user.email }}</div>
                </div>
                <div class="d-flex flex-column label">
                    <div class="name">Phone Number</div>
                    <div class="value">{{ buyer.user.phoneNumber || '(none)' }}</div>
                </div>
            </div>
            <div *ngIf="buyer?.hasContactData() ?? false">
                <div class="spacing"></div>
                <div class="subsection">
                <div class="header w100" style="position: relative;">
                    Contact
                </div>
                <div *ngIf="buyer.contact.assignedConcierge != null" class="d-flex flex-column label">
                    <div class="name">Assigned Concierge</div>
                    <div class="value">{{ buyer.contact.assignedConcierge }}</div>
                </div>
                <div>
                    <button class="intercomButton mt-2" (click)="openIntercom()" mat-raised-button>View in Intercom</button>
                </div>
                </div>
            </div>
            <div class="spacing"></div>
            <div class="subsection">
                <div class="header w100" style="position: relative;">
                    Referrals
                </div>
                <div class="d-flex flex-column label">
                    <div class="name">Referral code</div>
                    <div class="value">{{ buyer.referralCode }}</div>
                </div>
                <div class="d-flex flex-column label">
                    <div class="name">Referral discount</div>
                    <div class="value">{{ buyer.referralDiscountCents / 100 | currency:'USD' }}</div>
                </div>
                <div class="d-flex flex-column label">
                    <div class="name">Referral reward</div>
                    <div class="value">{{ buyer.referralRewardCents / 100 | currency:'USD' }}</div>
                </div>
            </div>
            <div class="spacing"></div>
            <div class="subsection">
                <div class="header w100" style="position: relative;">
                    Billing
                </div>
                <div>
                    <button class="viewBillingAccountButton mt-2" [routerLink]="['/payments/billing-accounts/', buyer.billingAccount.id]" mat-raised-button>View billing account</button>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-fill d-flex flex-column" style="margin-left: 52px; margin-right: 52px;">
        <div class="section">
            <div class="header d-flex w100 justify-content-between">
                <div>Overview</div>
                <div style="position: relative;">
                    <button *ngIf="!buyer.blocked" mat-button color="warn" (click)="block()" [disabled]="isPerformingAction() || !canBlock()" [class.spinner]="isBlocking" style="position: absolute; top: 50%; left: 50%; transform: translate(-100%, -50%);">Block</button>
                    <button *ngIf="buyer.blocked" mat-button color="accent" (click)="unblock()" [disabled]="isPerformingAction() || !canBlock()" [class.spinner]="isBlocking" style="position: absolute; top: 50%; left: 50%; transform: translate(-100%, -50%);">Unblock</button>
                </div>
            </div>
            <div class="break"></div>
            <div class="subsection">
                <div class="header">Offers</div>
                <table mat-table [dataSource]="recentOffers" *ngIf="recentOffers.length > 0">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> Id </th>
                        <td mat-cell *matCellDef="let offer"> {{ offer.id }} </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let offer">
                            <div class="badge-narrow" [ngClass]="{
                                'badge-narrow-success': offer.status === 'ACCEPTED' || offer.status === 'ORDERED',
                                'badge-narrow-error': offer.status === 'CANCELLED'
                             }">
                                {{ offer.status }}
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef> Description </th>
                        <td mat-cell *matCellDef="let offer"> {{ offer.listing.displayName }} </td>
                    </ng-container>
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef> Price </th>
                        <td mat-cell *matCellDef="let offer"> {{ offer.priceCents / 100 | currency:'USD' }} </td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef> Created </th>
                        <td mat-cell *matCellDef="let offer"> {{ offer.created | date:'MMM d, H:mm aa' }} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="recentOfferTableCols; sticky: true"></tr>
                    <tr mat-row *matRowDef="let offer; columns: recentOfferTableCols;"
                        [routerLink]="['/marketplace/offers/', offer.id]">
                    </tr>
                </table>
                <button mat-button [routerLink]="['/marketplace/offers']" [queryParams]="{ buyer: buyer.id }"
                    *ngIf="recentOffers.length > 0">View all offers →</button>
                <div style="opacity: 0.5; margin-top: 10px;" *ngIf="recentOffers.length == 0">No offers</div>
            </div>
            <div class="subsection" style="margin-top: 20px">
                <div class="header">Max Bids</div>
                <table mat-table [dataSource]="recentBids" *ngIf="recentBids.length > 0">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> Id </th>
                        <td mat-cell *matCellDef="let proxyBid"> {{ proxyBid.id }} </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let proxyBid">
                            <div class="badge-narrow" [ngClass]="{
                                'badge-narrow': proxyBid.status === 'AWAITING_PAYMENT', 
                                'badge-narrow-success': proxyBid.status === 'ACTIVE', 
                                'badge-narrow-orange': proxyBid.status === 'EXHAUSTED', 
                                'badge-narrow-error': proxyBid.status === 'CANCELLED'
                            }">
                                {{ proxyBid.status }}
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef> Description </th>
                        <td mat-cell *matCellDef="let proxyBid"> {{ proxyBid.listing.displayName }} </td>
                    </ng-container>
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef> Price </th>
                        <td mat-cell *matCellDef="let proxyBid"> {{ proxyBid.maxPriceCents / 100 | currency:'USD' }} </td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef> Created </th>
                        <td mat-cell *matCellDef="let proxyBid"> {{ proxyBid.created | date:'MMM d, H:mm aa' }} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="recentBidsTableCols; sticky: true"></tr>
                    <tr mat-row *matRowDef="let proxyBid; columns: recentBidsTableCols;"
                        [routerLink]="['/marketplace/max-bids/', proxyBid.id]">
                    </tr>
                </table>
                <button mat-button [routerLink]="['/marketplace/max-bids']" [queryParams]="{ buyer: buyer.id }" *ngIf="recentBids.length > 0">View all max bids →</button>
                <div style="opacity: 0.5; margin-top: 10px;" *ngIf="recentBids.length == 0">No max bids</div>
            </div>
            <div class="subsection" style="margin-top: 20px">
                <div class="header">Orders</div>
                <table mat-table [dataSource]="recentOrders" *ngIf="recentOrders.length > 0">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> Id </th>
                        <td mat-cell *matCellDef="let order"> {{ order.id }} </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let order">
                            <div class="badge-narrow" [ngClass]="{
                                'badge-narrow-success': order.status === 'DELIVERED',
                                'badge-narrow-error': order.status === 'CANCELLED'
                            }">
                                {{ order.status }}
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef> Description </th>
                        <td mat-cell *matCellDef="let order"> {{ order.listing.displayName }} </td>
                    </ng-container>
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef> Price </th>
                        <td mat-cell *matCellDef="let order"> {{ order.invoice.basePriceCents / 100 | currency:'USD' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef> Created </th>
                        <td mat-cell *matCellDef="let order"> {{ order.created | date:'MMM d, H:mm aa' }} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="recentOrderTableCols; sticky: true"></tr>
                    <tr mat-row *matRowDef="let order; columns: recentOrderTableCols;"
                        [routerLink]="['/marketplace/orders/', order.id]">
                    </tr>
                </table>
                <button mat-button [routerLink]="['/marketplace/orders']" [queryParams]="{ buyer: buyer.id }"
                    *ngIf="recentOrders.length > 0">View all orders →</button>
                <div style="opacity: 0.5; margin-top: 10px;" *ngIf="recentOrders.length == 0">No orders</div>
            </div>
            <div class="subsection" style="margin-top: 20px">
                <div class="header">Purchase Requests</div>
                <table mat-table [dataSource]="purchaseRequests" *ngIf="purchaseRequests.length > 0">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> Id </th>
                        <td mat-cell *matCellDef="let purchaseRequest"> {{purchaseRequest.id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let purchaseRequest">
                        <div class="badge-narrow" [ngClass]="getBadge(purchaseRequest)">
                            {{ purchaseRequest.statusEng }}
                        </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="offers">
                        <th mat-header-cell *matHeaderCellDef>Responses (Active/Total)</th>
                        <td mat-cell *matCellDef="let purchaseRequest">
                        {{getPurchaseRequestOffers(purchaseRequest)}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="model">
                        <th mat-header-cell *matHeaderCellDef> Model </th>
                        <td mat-cell *matCellDef="let purchaseRequest">
                        <model-chip [model]="purchaseRequest.model" trim="40" extraShort="true"></model-chip>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef> Price </th>
                        <td mat-cell *matCellDef="let purchaseRequest">
                        {{ purchaseRequest.priceDollars }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expiration">
                        <th mat-header-cell *matHeaderCellDef> Expiration </th>
                        <td mat-cell *matCellDef="let purchaseRequest"> {{purchaseRequest.expiration | date:'medium' }} </td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef> Created </th>
                        <td mat-cell *matCellDef="let purchaseRequest"> {{purchaseRequest.created | date:'medium' }} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="prTableCols; sticky: true"></tr>
                    <tr mat-row *matRowDef="let purchaseRequest; columns: prTableCols;" [routerLink]="['/marketplace/purchase-requests/', purchaseRequest.id]">
                </table>
                <button mat-button [routerLink]="['/marketplace/purchase-requests']" [queryParams]="{ buyers: buyer.id }"
                *ngIf="purchaseRequests.length > 0">View all purchase requests →</button>
                <div style="opacity: 0.5; margin-top: 10px;" *ngIf="purchaseRequests.length == 0">No purchase requests</div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="d-flex flex-column align-items-center m-5">
        <mat-spinner diameter="34" class="loading-spinner"></mat-spinner>
    </div>
</ng-template>
