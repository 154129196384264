import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { DndService } from '@ng-dnd/core';

@Component({
    selector: 'grid-image',
    templateUrl: './image-grid-item.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageGridItemComponent {
    @Input() slotId!: string;
    @Input() bgImage!: string;
    @Input() image?: string;

    imgSource = this.dnd.dragSource("IMAGE", {
        beginDrag: () => {
            return { id: this.slotId };
        },
        canDrag: () => {
            return this.image != null;
        },
    });
    constructor(private dnd: DndService) { }

    ngOnDestroy() {
        this.imgSource.unsubscribe();
    }

    getImage(): string {
        if (this.image == null) {
            return this.bgImage;
        }
        return this.image;
    }
};
