<div class="dialog-content">
    <div class="title">Update Seller Profile</div>
    <div class="break"></div>
    <div *ngIf="!isLoading() else loading">
        <form [formGroup]="formGroup">
            <div class="section-title w100">General</div>
            <mat-form-field appearance="outline">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type">
                    <mat-option *ngFor="let type of allTypes" [value]="type">
                        {{type}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-checkbox formControlName="trusted" class="mb-3">Trusted</mat-checkbox>
            <div class="section-title w100">Offers</div>
            <div>
                <mat-checkbox formControlName="offersAllowed">Offers allowed</mat-checkbox>
                <mat-form-field appearance="outline" *ngIf="formControls.offersAllowed.value">
                    <mat-label>Auto Decline Offers Under (%)</mat-label>
                    <input matInput placeholder="15.0" formControlName="autoDeclineOffersUnderPercent">
                </mat-form-field>
            </div>
            <div class="section-title w100" #tooltip="matTooltip"
                matTooltip="The fees set here override the default fees determined by the seller level.">Fee overrides
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Commission Rate (%)</mat-label>
                <input matInput placeholder="4.5" formControlName="overrideCommissionRatePercent">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Seller Fee ($)</mat-label>
                <input matInput placeholder="25.45" formControlName="overrideSellerFeeDollars">
            </mat-form-field>
            <div class="section-title w100" #tooltip="matTooltip"
                matTooltip="The values provided here are used to sync seller's external listings to the platform.">
                External syncing</div>
            <mat-form-field appearance="outline">
                <mat-label>Listing Sitemap URL</mat-label>
                <input matInput placeholder="https://www.getwatches.com/listings"
                    formControlName="externalListingSitemapUrl">
            </mat-form-field>
            <mat-checkbox formControlName="markupExternalListings">Markup external listings</mat-checkbox>
            <mat-form-field appearance="outline" *ngIf="formControls.markupExternalListings.value">
                <mat-label>External Listing Markup (%)</mat-label>
                <input matInput placeholder="2.0" formControlName="externalListingsMarkupPercent">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Internal Notes</mat-label>
                <textarea matInput formControlName="internalNotes" [maxLength]="1024" style="min-height: 160px;"></textarea>
                <mat-hint align="end">{{formControls.internalNotes.value?.length ?? 0}}/1024</mat-hint>
              </mat-form-field>
        </form>
    </div>
    <ng-template #loading>
        <div class="d-flex flex-column align-items-center m-5">
            <mat-spinner diameter="34" class="loading-spinner"></mat-spinner>
        </div>
    </ng-template>
    <div class="break"></div>
    <div class="d-flex justify-content-between">
        <div class="d-flex">
            <button mat-raised-button color="warn" (click)="deleteButtonPressed();">Delete</button>
        </div>
        <div class="d-flex flex-row-reverse w100">
            <button mat-raised-button color="primary" [disabled]="!formGroup.valid || !formGroup.dirty || isSaving"
                [class.spinner]="isSaving" (click)="saveButtonPressed();">Save</button>
            <button mat-button class="mx-2" [disabled]="isSaving" (click)="cancelButtonPressed();">Cancel</button>
        </div>
    </div>
</div>
