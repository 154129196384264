<h3>Crop Image</h3>
<div class="mw-100 mx-auto">
  <image-cropper
    [autoCrop]="false"
    [imageURL]="data.image.startsWith('http') ? data.image : null"
    [imageBase64]="!data.image.startsWith('http') ? data.image : null"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
</div>
<div class="d-flex justify-content-between">
  <div class="mt-3">
    <button mat-raised-button color="primary" (click)="onSave()">Save</button>
  </div>
  <div class="mt-3">
    <button mat-raised-button color="secondary" (click)="onClose()">Close</button>
  </div>
</div>