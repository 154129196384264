import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subscription } from 'rxjs';
import { ListingsQuery, ListingsService } from 'src/app/_services/listings.service';
import { ToolbarService, ToolbarController } from 'src/app/_services/toolbar.service';
import Listing from 'src/app/models/listing.model';
import Series from 'src/app/models/series.model';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import SellerProfile from 'src/app/models/seller-profile.model';
import { environment } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Model from 'src/app/models/model.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ModelsService } from 'src/app/_services/models.service';
import { SeriesService } from 'src/app/_services/series.service';

@Component({
  selector: 'app-listings-home',
  templateUrl: './listings-home.component.html',
  styleUrls: ['./listings-home.component.css']
})
export class ListingsHomeComponent implements OnInit, OnDestroy, ToolbarController {
  title = new BehaviorSubject<string>('Listings');

  listings: Listing[] = [];
  displayedColumns: string[] = ['flagged', 'id', 'status', 'visibility', 'pricingModel', 'model', 'condition', 'price', 'seller', 'created', 'actions'];
  statuses: object[] = [
    { label: 'All', value: 'ALL' },
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Pending Review', value: 'PENDING_REVIEW' },
    { label: 'Published', value: 'PUBLISHED' },
    { label: 'Rejected', value: 'REJECTED' },
    { label: 'Ordered', value: 'ORDERED' },
    { label: 'Archived', value: 'ARCHIVED' },
    { label: 'Suspended', value: 'SUSPENDED' },
  ]

  modelFilterControl = new FormControl("");
  seriesFilterControl = new FormControl("");
  searchResults: BehaviorSubject<Model[]> = new BehaviorSubject<Model[]>([]);
  seriesSearchResults: BehaviorSubject<Series[]> = new BehaviorSubject<Series[]>([]);
  statusFilter: string = 'ALL';
  modelFilter!: number | null;
  seriesFilter!: number | null;
  sellerProfileFilter!: number | null;
  inventoryNumberFilter!: BehaviorSubject<string | null>;
  flaggedFilter: boolean | null = null;
  activePricingModelFilter: string | null = null;
  tagsFilterControl = new FormControl<number[]>([]);
  tagsFilter: number[] | null = null;
  includePrivateFilter: string | null = null;

  page: number = 0;
  sortKey: string | null = null;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];
  isLoading = true;
  private loadingSubscription: Subscription | null = null

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    private toolbarService: ToolbarService,
    private listingsService: ListingsService,
    private modelsService: ModelsService,
    private seriesService: SeriesService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.inventoryNumberFilter = new BehaviorSubject<string | null>(null);
    this.inventoryNumberFilter.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(_ => {
        // TODO: Fix this. Uncommenting this resets the page when the component mounts.
        // this.page = 0;
        this.updateUrlParametersIfNeeded();
      });

    this.activatedRoute.queryParams.subscribe(params => {
      this.statusFilter = params.status ? params.status : 'ALL';
      this.modelFilter = params.model ? params.model : null;
      this.seriesFilter = params.series ? params.series : null;
      this.page = params.page ? params.page : 0;
      this.sellerProfileFilter = params.seller ? params.seller : null;
      this.inventoryNumberFilter.next(params.inventoryNumber ? params.inventoryNumber : null);
      this.flaggedFilter = params.flagged ? params.flagged : null;
      this.tagsFilter = params.tags ? params.tags : null;
      this.includePrivateFilter = params.includePrivate ? params.includePrivate : "include_private";
      if (this.tagsFilter) {
        this.tagsFilterControl.setValue(this.tagsFilter);
      }
      this.sortKey = params.sort ? params.sort : null;

      if (this.modelFilter != null && this.modelFilterControl.value == null) {
        modelsService.getModel(this.modelFilter).subscribe(model => {
          if (model == null) {
            return;
          }

          this.modelFilterControl.setValue(`${model.brand.displayName ?? model.brand.name} ${model.displayName ?? model.name}`);
        })
      }
      if (this.seriesFilter != null && this.seriesFilterControl.value == null) {
        seriesService.getSeries(this.seriesFilter).subscribe(series => {
          if (series == null) {
            return;
          }

          this.seriesFilterControl.setValue((series.brand.displayName ?? series.brand.name) + " " + (series.displayName ?? series.name));
        });
      }
      this.modelFilterControl.valueChanges.subscribe(value => this.onSearchTextChanged(value || ''));
      this.seriesFilterControl.valueChanges.subscribe(value => this.onSeriesSearchTextChanged(value || ''));
      this.activePricingModelFilter = params.activePricingModel ? params.activePricingModel : null;

      this.fetchListings(this.page);
    })
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  get loading(): boolean {
    return this.listings.length === 0;
  }

  pageChangeEvent = (event: PageEvent) => {
    this.page = event.pageIndex
    this.updateUrlParametersIfNeeded();
  }

  onSearchTextChanged(query: string) {
    if (query.length < 2) {
      this.searchResults.next([]);
      return;
    }

    this.modelsService.searchModels(query).subscribe({
      next: models => {
        this.searchResults.next(models);
      },
      error: error => {
        console.log(error)
      }
    })
  }

  onSeriesSearchTextChanged(query: string) {
    if (query.length < 2) {
      this.searchResults.next;
      return;
    }

    this.seriesService.searchSeries(query).subscribe({
      next: series => {
        this.seriesSearchResults.next(series);
      }
    });
  }

  fetchListings = (index: number) => {
    this.isLoading = true;
    var queryParams : ListingsQuery = {
        status: this.statusFilter === "ALL" ? undefined : this.statusFilter,
        inventoryNumbers: this.inventoryNumberFilter?.value?.split(",").map(inventoryNumber => inventoryNumber.trim() + "%"),

        sortKey: this.sortKey == null ? "-created" : this.sortKey,
        start: this.pageSize * index,
        series: this.seriesFilter ? this.seriesFilter : undefined,
        model: this.modelFilter ? this.modelFilter : undefined,
        sellerProfileFilter: this.sellerProfileFilter ? this.sellerProfileFilter : undefined,
        flagged: this.flaggedFilter ? this.flaggedFilter : undefined,
        tags: this.tagsFilter ? this.tagsFilter : undefined,
        activePricingModel: this.activePricingModelFilter ? this.activePricingModelFilter : undefined,
        viewPrivate: this.includePrivateFilter ? this.includePrivateFilter : undefined,
    };
    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription =  this.listingsService.getListings(queryParams).subscribe({
      next: (response: any) => {
        this.listings = response.data;
        this.length = response.totalCount;
        this.isLoading = false;
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.listings = [];
        this.length = 0;
        this.isLoading = false;
        this.loadingSubscription = null;
      }
    });
  };

  onSelectStatus = (event: any) => {
    this.page = 0;
    this.updateUrlParametersIfNeeded();
  }

  onSelectSellerFilter = (seller: SellerProfile) => {
    this.sellerProfileFilter = seller.id;
    this.page = 0;
    this.updateUrlParametersIfNeeded();
  };

  onSearchResultClicked(event: MatAutocompleteSelectedEvent) {
    var model = event.option.value;
    this.modelFilter = model.id;
    this.modelFilterControl.setValue(`${model.brand.displayName ?? model.brand.name} ${model.displayName ?? model.name}`)
    this.searchResults.next([]);
    this.page = 0;
    this.updateUrlParametersIfNeeded();
  }

  onSeriesSearchResultClicked(event: MatAutocompleteSelectedEvent) {
    var series = event.option.value;
    this.seriesFilter = series.id;
    this.seriesFilterControl.setValue((series.brand.displayName ?? series.brand.name) + " " + (series.displayName ?? series.name));
    this.searchResults.next([]);
    this.page=0;
    this.updateUrlParametersIfNeeded();
  }

  onChangeFlaggedFilter(event: any): void {
    this.updateUrlParametersIfNeeded();
  }

  onChangePricingModelFilter(event: any): void {
    this.updateUrlParametersIfNeeded();
  }

  onChangePrivateFilter(event: any): void {
    this.updateUrlParametersIfNeeded();
  }

  onChangeTagsFilter = (tagIds: number[]) => {
    this.tagsFilter = tagIds.length > 0 ? tagIds : null;
    this.page = 0;
    this.updateUrlParametersIfNeeded();
  };

  clearModelSelection = () => {
    this.modelFilter = null;
    this.modelFilterControl.reset();
    this.updateUrlParametersIfNeeded();
  }

  handleMenuClick = (event: any) => {
    event.stopPropagation();
  }

  handlePricingModelClick = (event: any, listing: Listing) => {
    event.stopPropagation()
    this.activePricingModelFilter = listing.activePricingModel
    this.updateUrlParametersIfNeeded()
  }

  onArchive(listingId: number): void {
    this.listingsService.archiveListing(listingId).subscribe({
      next: () => {
        this.fetchListings(this.page);
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  onFlag(listingId: number, flagged: boolean): void {
    this.listingsService.updateListing(listingId, {flagged: flagged}).subscribe({
      next: () => {
        this.fetchListings(this.page);
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  onAddListingButtonPressed(): void {
    this.router.navigate(['marketplace', 'listings', 'new']);
  }

  onSort(event: Sort): void {
    if (event.active == 'created' && event.direction == 'asc') {
      this.sortKey = 'created';
    } else if (event.active == 'created' && event.direction == 'desc') {
      this.sortKey = '-created';
    } else if (event.active == 'model' && event.direction == 'asc') {
      this.sortKey = 'model.id';
    } else if (event.active == 'model' && event.direction == 'desc') {
      this.sortKey = '-model.id';
    } else if (event.active == 'condition' && event.direction == 'asc') {
      this.sortKey = 'condition';
    } else if (event.active == 'condition' && event.direction == 'desc') {
      this.sortKey = '-condition';
    } else if (event.active == 'seller' && event.direction == 'asc') {
      this.sortKey = 'sellerProfile.id';
    } else if (event.active == 'seller' && event.direction == 'desc') {
      this.sortKey = '-sellerProfile.id';
    } else if (event.active == 'price' && event.direction == 'asc') {
      this.sortKey = 'price';
    } else if (event.active == 'price' && event.direction == 'desc') {
      this.sortKey = '-price';
    } else {
      this.sortKey = null;
    }
    this.page = 0;
    this.updateUrlParametersIfNeeded()
  }

  openInNewTab(listing: Listing) {
    window.open(location.origin + '/marketplace/listings/' + listing.id);
  }

  onDuplicate(listingId: number): void {
    this.router.navigate(
      ['marketplace', 'listings', 'new'],
      { queryParams: { duplicateFrom: listingId } }
    );
  }

  onViewInBezel(listingId: number): void {
    window.open(environment.bezelDomain + "/listings/" + listingId, "_blank");
  }

  onApprove(listingId: number): void {
    this.listingsService.approveListing(listingId, '').subscribe({
      next: () => {
        this.fetchListings(this.page);
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      status: this.statusFilter == 'ALL' ? null : this.statusFilter,
      model: this.modelFilter,
      series: this.seriesFilter,
      seller: this.sellerProfileFilter,
      inventoryNumber: this.inventoryNumberFilter.value,
      flagged: this.flaggedFilter == null ? null : this.flaggedFilter,
      tags: this.tagsFilter,
      page: this.page == 0 ? null : this.page,
      sort: this.sortKey == null ? null : this.sortKey,
      activePricingModel: this.activePricingModelFilter == null ? null : this.activePricingModelFilter,
      includePrivate: this.includePrivateFilter != null ? this.includePrivateFilter : null,
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
