<div class="image-slot" [dropTarget]="target">
  <grid-image [slotId]="slotData.enum" [bgImage]="slotData.bgImage" [image]="getImage()" />
  <div class="display-area">
    <div style="padding-left:10px">{{slotData.displayName}}</div>
    <div>
      <button mat-fab class="icon" (click)="onClickCrop()">
        <mat-icon>crop</mat-icon>
      </button>
      <button mat-fab class="icon" color="warn" (click)="onClickClose()">
        <mat-icon>remove</mat-icon>
      </button>
    </div>
  </div>
</div>