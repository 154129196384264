import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { ToolbarService, ToolbarController } from 'src/app/_services/toolbar.service';
import Offer, { allStatuses, offerStatuses, offerVersions } from 'src/app/models/offer.model';
import { PageEvent } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import BuyerProfile from 'src/app/models/buyer-profile.model';
import { BuyersService } from 'src/app/_services/buyers.service';
import { OffersService } from 'src/app/_services/offers.service';
import { Sort } from '@angular/material/sort';
import { clientNames } from 'src/app/models/clients'
import Listing from 'src/app/models/listing.model';
import { ListingsService } from 'src/app/_services/listings.service';

@Component({
  selector: 'app-offers-home',
  templateUrl: './offers-home.component.html',
  styleUrls: ['./offers-home.component.css']
})
export class OffersHomeComponent implements OnInit, OnDestroy, ToolbarController  {
  offers: Offer[] = [];
  displayedColumns: string[] = ['id', 'status', 'listing', 'priceCents', 'buyer', 'created', 'version', 'client'];

  listingFilterControl = new FormControl<Listing | null>(null);
  buyerFilterControl = new FormControl<BuyerProfile | null>(null)

  statusFilter: string = 'ALL';
  listingFilter!: number | null;
  buyerFilter!: number | null;
  clientFilter!: string | null;
  versionFilter!: number | null;
  acceptedFilter!: string | null;

  title = new BehaviorSubject<string>('Offers');
  page = 0;
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [20];
  sortKey!: string | null;
  isLoading = true
  private loadingSubscription: Subscription | null = null
  
  private _destroyed = new BehaviorSubject<boolean>(false);

  @Input() allStatuses: object;
  @Input() offerStatuses: object;
  @Input() offerVersions: object;
  clientNames: object;
  
  constructor(
    private toolbarService: ToolbarService,
    private offersService: OffersService,
    private buyersService: BuyersService,
    private listingsService: ListingsService,
    private router: Router,
    private activatedRoute: ActivatedRoute
    ) {
      this.sortKey = '-created';
      this.activatedRoute.queryParams.subscribe(params => {
        this.statusFilter = params.status ? params.status : 'ALL';
        this.listingFilter = params.listing ? params.listing : null;
        this.buyerFilter = params.buyer ? params.buyer : null;
        this.clientFilter = params.client ? params.client : null;
        this.versionFilter = params.version ? params.version : null;
        this.acceptedFilter = params.accepted ? params.accepted : null;
        this.page = params.page ? params.page : 0;

        if (this.buyerFilter && !(this.buyerFilterControl.value instanceof BuyerProfile)) {
          buyersService.getBuyerProfile(this.buyerFilter).subscribe({
            next: (buyer: BuyerProfile) => {
              this.buyerFilterControl.setValue(buyer);
            }
          })
        }
        if (this.listingFilter && !(this.listingFilterControl.value instanceof Listing)) {
          listingsService.getListing(this.listingFilter).subscribe({
            next: (listing: Listing) => {
              this.listingFilterControl.setValue(listing);
            }
          })
        }
  
        this.fetchOffers(this.page);
      })
      this.allStatuses = allStatuses;
      this.offerStatuses = offerStatuses;
      this.offerVersions = offerVersions;
      this.clientNames = clientNames;

      this.listingFilterControl.valueChanges.subscribe({
        next: () => {
          if (this.listingFilterControl.value instanceof Listing) {
            this.listingFilter = this.listingFilterControl.value.id;
          } else {
            this.listingFilter = null;
          }
          this.updateUrlParametersIfNeeded();
        }
      })
      this.buyerFilterControl.valueChanges.subscribe({
        next: () => {
          if (this.buyerFilterControl.value instanceof BuyerProfile) {
            this.buyerFilter = this.buyerFilterControl.value.id;
          } else {
            this.buyerFilter = null;
          }
          this.updateUrlParametersIfNeeded();
        }
      })
    }
    
  ngOnInit(): void {
    this.toolbarService.setController(this);
  }
  
  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  pageChangeEvent = (event: PageEvent) => {
    this.page = event.pageIndex;
    this.updateUrlParametersIfNeeded();
  }

  fetchOffers = (index: number) => {
    this.isLoading = true
    
    var statusForRequest = this.statusFilter === "ALL" ? null : this.statusFilter;

    if (this.loadingSubscription != null) {
      this.loadingSubscription.unsubscribe();
    }
    let boolAcceptedFilter = this.acceptedFilter == null ? null : (this.acceptedFilter == "true" ? true : false);

    this.loadingSubscription = this.offersService.getOffers(
        this.pageSize, this.pageSize * index, statusForRequest, this.listingFilter, this.buyerFilter, this.clientFilter,
        this.versionFilter, boolAcceptedFilter , this.sortKey).subscribe({
      next: (response: any) => {
        this.offers = response.data;
        this.length = response.totalCount;
        this.isLoading = false
        this.loadingSubscription = null;
      },
      error: (error: any) => {
        console.log(error);
        this.offers = [];
        this.length = 0;
        this.isLoading = false
        this.loadingSubscription = null;
      }
    });
  };

  onSort(event: Sort): void {
    if (event.active == 'created' && event.direction == 'asc') {
      this.sortKey = 'created';
    } else if (event.active == 'created' && event.direction == 'desc') {
      this.sortKey = '-created';
    } else {
      this.sortKey = null;
    }
    this.page = 0;
    this.fetchOffers(this.page);
  }

  onSelectStatus = (event: any) => {
    this.updateUrlParametersIfNeeded();
  }
  
  onSelectClient = (event: any) => {
    this.updateUrlParametersIfNeeded();
  }

  onSelectVersion = (event: any) => {
    this.updateUrlParametersIfNeeded();
  }
  
  onSelectAccepted = (event: any) => {
    this.updateUrlParametersIfNeeded();
  }

  private updateUrlParametersIfNeeded() {
    var queryParams: any = {
      status: this.statusFilter == 'ALL' ? null : this.statusFilter,
      listing: this.listingFilter,
      buyer: this.buyerFilter == null ? null : this.buyerFilter,
      client: this.clientFilter == null ? null : this.clientFilter,
      version: this.versionFilter == null ? null : this.versionFilter,
      page: this.page == 0 ? null : this.page,
      accepted: this.acceptedFilter == null ? null : this.acceptedFilter
    }

    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
