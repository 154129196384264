import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { DndService } from '@ng-dnd/core';
import { ImageMetadata } from 'src/app/models/image.model';

interface DragItem {
  id: string;
}

export class ItemData {
  image!: string;
  internalId!: number;    
  metadata!: ImageMetadata;

  constructor(image : string, imageId: number) {
    this.image = image;
    this.internalId = imageId;
    this.metadata = new ImageMetadata();
    this.metadata.state = "EMPTY";
  }
}

export class SlotData {
  enum!: string;
  displayName!: string;
  bgImage!: string;
  callback!: (name1: string, name2: string, func: string) => void;
}

@Component({
  selector: 'image-grid-slot',
  templateUrl: './image-grid-slot.component.html',
  styleUrls: ['./image-grid-slot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageGridSlotComponent {
  @Input() slotData!: SlotData;
  @Input() itemData?: ItemData;

  target = this.dnd.dropTarget<DragItem>("IMAGE", {

    canDrop: monitor => { 
      return true;
    },
    drop: monitor => {
      this.slotData.callback(this.slotData.enum, monitor.getItem()!.id, "swap");
    },
  });

  constructor(private dnd: DndService) {}

  onClickCrop() {
    this.slotData.callback(this.slotData.enum, "", "crop");
  }

  onClickClose() {
    this.slotData.callback(this.slotData.enum, "", "close");
  }

  getImage() {
    if (!!this.itemData) {
      return this.itemData.image;
    }
    return null;
  }

  getImageId() {
    if (!!this.itemData) {
      return this.itemData.internalId;
    }
    return null;
  }

  ngOnDestroy() {
    this.target.unsubscribe();
  }
}