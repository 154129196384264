import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, OnDestroy, OnInit, Inject, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, ReplaySubject, Observable, forkJoin, throwError, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { capitalize, replace, remove } from 'lodash';
import Accessory from 'src/app/models/accessory.model';
import BuyerProfile from 'src/app/models/buyer-profile.model';
import SellerProfile from 'src/app/models/seller-profile.model';
import { CatalogService } from 'src/app/_services/catalog.service';
import { ListingsService } from 'src/app/_services/listings.service';
import { ImageService } from 'src/app/_services/image.service';
import { TitlePathElement, ToolbarController, ToolbarService } from 'src/app/_services/toolbar.service';
import IssueTemplate from 'src/app/models/issue-template.model';
import IssueResponse from 'src/app/models/issue-response.model';
import ListingChallenge from 'src/app/models/listing-challenge.model';
import Tag from 'src/app/models/tag.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageMetadata } from 'src/app/models/image.model';
import { IngestionRequestsService } from 'src/app/_services/ingestion-requests.service';
import IngestionRequest from 'src/app/models/ingestion-request.model';
import Listing from 'src/app/models/listing.model';
import SellerLevel from 'src/app/models/seller-level.model';
import { SellersService } from 'src/app/_services/sellers.service';
import { ErrorDialog } from 'src/app/common/error-dialog/error-dialog.component';
import Model from 'src/app/models/model.model';
import { TagsService } from 'src/app/_services/tags.service';
import { Location } from '@angular/common';
import { ModelsService } from 'src/app/_services/models.service';
import { ImageGridComponent } from 'src/app/common/image-grid/listing-image-grid.component';

@Component({
  selector: 'app-listings-create',
  templateUrl: './listings-create.component.html',
  styleUrls: ['./listings-create.component.scss']
})
export class ListingsCreateComponent implements OnInit, OnDestroy, ToolbarController {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  @ViewChild(ImageGridComponent) private imageGrid!: ImageGridComponent;

  titlePath!: BehaviorSubject<TitlePathElement[]>;
  title!: BehaviorSubject<string>;

  accessories: Accessory[] = []
  issues: IssueTemplate[] = []
  allTags: Map<Number, Tag> = new Map();
  availableTags: Tag[] = [];
  tagsChanged: boolean = false;

  selectedSeller = new FormControl<SellerProfile>(new SellerProfile(), Validators.required);
  selectedAccessories = new FormControl();
  selectedTag = new FormControl();
  selectedModel!: number | null

  modelText!: string | null
  issueResponses: IssueResponse[] = [];

  manufactureMonth = new FormControl('');
  manufactureYear = new FormControl('');
  purchaseMonth = new FormControl('');
  purchaseYear = new FormControl('');
  auctionStartDate = new FormControl<Date | null>(null);
  auctionEndDate = new FormControl<Date | null>(null);
  auctionEstimateLowerBound = new FormControl<number | null>(null);
  auctionEstimateUpperBound = new FormControl<number | null>(null);
  startingPrice = new FormControl(0);
  lotEssay = new FormControl<string | null>(null);
  priceGuaranteeType = new FormControl<string | null>(null);
  reserveDollars = new FormControl<number | null>(null);
  guarantor = new FormControl<BuyerProfile>(new BuyerProfile());
  guaranteedBidDollars = new FormControl<number | null>(null);

  condition = new FormControl('', Validators.required);
  conditionOptions = [
    "UNWORN",
    "PREOWNED"
  ]
  disclaimerOptions : Map<string, boolean> = new Map<string, boolean>([
    ["BEZEL_OWNED", false],
    ["GUARANTEED_BID", false],
    ["CITES_REGULATED", false]
  ]);
  externalUrl = new FormControl('');
  externalSyncEnabled = new FormControl(true);
  externalPriceSyncEnabled = new FormControl(true);
  inventoryNumber = new FormControl('');
  originalOwner: boolean = false

  pricingModel = new FormControl('', Validators.required);

  price = new FormControl('', Validators.required);
  offersAllowed = new FormControl(true);
  autoDeclineOffersUnderCents = new FormControl('');
  promotionPriceCents!: number | null;
  promotionType!: string | null;
  sellerNotes = new FormControl<string | null>(null);
  desiredNetPayoutCents = new FormControl<number | null>(null);

  autoDuplicateOnSale = new FormControl(false);

  existingImages?: Map<string, ImageMetadata>;

  listingLoadComplete: boolean = false;
  submitting: boolean = false;

  searchResults: BehaviorSubject<Model[]> = new BehaviorSubject<Model[]>([]);
  private mouseHoveringOverResults: boolean = false;
  hoveredModel!: Model | null
  private searchBarFocused: boolean = false;
  hideSearchResults: boolean = false;

  private _listingId!: number
  private _listing!: Listing | null

  private _ingestionRequestId!: number
  ingestionRequest!: IngestionRequest

  private _sellerLevels: SellerLevel[] | null = null;
  private sellerLevel!: SellerLevel;

  private _destroyed = new ReplaySubject<boolean>(1);

  editing: boolean = false;

  private _hasPreviousRoute = false;

  constructor(
    public dialog: MatDialog,
    private toolbarService: ToolbarService,
    private listingsService: ListingsService,
    private tagsService: TagsService,
    private ingestionRequestsService: IngestionRequestsService,
    private modelsService: ModelsService,
    private catalogService: CatalogService,
    private imageService: ImageService,
    private sellersService: SellersService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {

    this.startingPrice.disable();

    const routeParams = this.route.snapshot.paramMap;
    if (routeParams.get('listingId')) {
      this._listingId = Number(routeParams.get('listingId'));
      this.editing = true;
    } else {
      this.route.queryParams.subscribe(params => {
        if (params.duplicateFrom) {
          this._listingId = Number(params.duplicateFrom);
        }
        if (params.ingestionRequest) {
          this._ingestionRequestId = Number(params.ingestionRequest);
        }
      });
    }

    if (router.getCurrentNavigation()?.previousNavigation) {
      this._hasPreviousRoute = true;
    }

    this.sellersService.getSellerLevels().subscribe(sellerLevels => {
      this._sellerLevels = sellerLevels;
      this.updateSellerLevelIfNeeded();
    })
    this.catalogService.getAccessories().subscribe({
      next: (accessories: Accessory[]) => {
        this.accessories = accessories;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.listingsService.getIssues().subscribe({
      next: (issues: IssueTemplate[]) => {
        this.issues = issues;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.tagsService.getTags(null).subscribe({
      next: (tags: Tag[]) => {
          for (let tag of tags) {
              this.allTags.set(tag.id, tag);
          }
      }
    });
    if (this._listingId) {
      listingsService.getListing(this._listingId).subscribe({
        next: (listing) => {
          this._listing = listing
          this.selectedSeller.setValue(Object.assign(new SellerProfile(), listing.sellerProfile));
          this.onSearchResultClicked(listing.model);
          this.selectedAccessories.setValue(listing.accessories.map(a => a.id));
          this.issueResponses = listing.issues.map(i => Object.assign(new IssueResponse, { issue: { id: i.issue.id }, answer: i.answer }));
          this.availableTags = this.calculateAvailableTags(this.allTags, listing.tags);
          this.manufactureMonth.setValue(listing.manufactureMonth?.toString() ?? "");
          this.manufactureYear.setValue(listing.manufactureYear?.toString() ?? "");
          this.purchaseMonth.setValue(listing.purchaseMonth?.toString() ?? "");
          this.purchaseYear.setValue(listing.purchaseYear?.toString() ?? "");
          this.originalOwner = listing.originalOwner;
          this.offersAllowed.setValue(listing.offersAllowed);
          this.externalSyncEnabled.setValue(listing.externalSyncEnabled);
          this.externalPriceSyncEnabled.setValue(listing.externalPriceSyncEnabled);
          this.pricingModel.setValue(listing.activePricingModel ?? null);
          this.price.setValue((listing.listedPriceCents / 100).toString());
          this.autoDeclineOffersUnderCents.setValue(listing.autoDeclineOffersUnderCents ? (listing.autoDeclineOffersUnderCents / 100).toString() : '');
          this.condition.setValue(listing.condition);
          this.externalUrl.setValue(listing.externalUrl);
          this.inventoryNumber.setValue(listing.inventoryNumber);

          this.existingImages = new Map<string, ImageMetadata>();
          for (let image of listing.images) {
            if (!!image.image) {
              let metadata = new ImageMetadata();
              metadata.savedImageId = image.image.id;
              metadata.state = "REGISTERED";
              metadata.uploadUri = image.image.url;
              this.existingImages.set(image.type, metadata);
            }
          }

          this.promotionPriceCents = listing.promotion?.priceCents
          this.promotionType = listing.promotion?.type
          this.autoDuplicateOnSale.setValue(listing.autoDuplicateOnSale);
          this.desiredNetPayoutCents.setValue(listing.desiredNetPayoutCents);
          this.sellerNotes.setValue(listing.sellerNotes);
          if (listing.activePricingModel == "AUCTION") {
             if (listing.auctionInfo?.startDate) {
              this.auctionStartDate.setValue(new Date(listing.auctionInfo.startDate));
             }
             if (listing.auctionInfo?.endDate) {
              this.auctionEndDate.setValue(new Date(listing.auctionInfo.endDate));
             }
             if (listing.auctionInfo?.startingPriceCents != undefined) {
              this.startingPrice.setValue(listing.auctionInfo.startingPriceCents / 100)
             }
             if (listing.auctionInfo?.estimatedValueRange?.lowerBoundCents) {
              this.auctionEstimateLowerBound.setValue(listing.auctionInfo!.estimatedValueRange!.lowerBoundCents / 100.0);
             }
             if (listing.auctionInfo?.estimatedValueRange?.upperBoundCents) {
              this.auctionEstimateUpperBound.setValue(listing.auctionInfo!.estimatedValueRange!.upperBoundCents / 100.0);
             }
             for (let disc of listing.auctionInfo?.disclaimers) {
               this.disclaimerOptions.set(disc, true);
             }
             if (listing.auctionInfo?.hasReserve) {
              this.priceGuaranteeType.setValue("RESERVE");
              this.reserveDollars.setValue(listing.auctionInfo.reserveCents! / 100);
             } else if (listing.auctionInfo?.guarantorBuyerProfile?.user || listing.auctionInfo?.guaranteedBidCents) {
              this.priceGuaranteeType.setValue("GUARANTEED_BID");
              this.guarantor.setValue(Object.assign(new BuyerProfile(), listing.auctionInfo.guarantorBuyerProfile));
              if (listing.auctionInfo?.guaranteedBidCents) {
                this.guaranteedBidDollars.setValue(listing.auctionInfo.guaranteedBidCents / 100);
              }
             }
             this.lotEssay.setValue(listing.auctionInfo.essay ?? null);
          }

          this.listingLoadComplete = true;
          if (!this.editing) {
            this._listing = null
          }
          this.tagsChanged = false;
        },
        error: (error: any) => {
          console.log(error);
        }
      });
    }
    if (this._ingestionRequestId) {
      ingestionRequestsService.getIngestionRequest(this._ingestionRequestId).subscribe({
        next: (ingestionRequest) => {
          this.externalUrl.setValue(ingestionRequest.url)
          this.externalUrl.disable()

          this.selectedSeller.setValue(Object.assign(new SellerProfile(), ingestionRequest.sellerProfile));
          this.updateSellerLevelIfNeeded();

          this.ingestionRequest = ingestionRequest
        }
      });
    }

    this.priceGuaranteeType.valueChanges.subscribe({
      next: value => {
        this.updateDisclaimer('GUARANTEED_BID', value === 'GUARANTEED_BID')
      }
    })

    if (this.editing) {
      this.titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Listings", path: ['/marketplace', 'listings', this._listingId.toString(), 'edit'] }]);
      this.title = new BehaviorSubject<string>("Edit Listing");
    } else {
      this.titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Listings", path: ['/marketplace', 'listings'] }]);
      this.title = new BehaviorSubject<string>("Create Listing");
    }
  }

  private updateSellerLevelIfNeeded(): void {
    if (this.selectedSeller.value == null || this._sellerLevels == null) {
      return;
    }

    Object.values(this._sellerLevels).forEach(sellerLevel => {
      if (sellerLevel.level == this.selectedSeller.value?.sellerLevel) {
        this.sellerLevel = sellerLevel
      }
    })
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  get loading(): boolean {
    var loading = !this.accessories || !this.issues;
    if (this._listingId) {
      loading = loading || !this.listingLoadComplete
    }
    if (this._ingestionRequestId) {
      loading = loading || !this.ingestionRequest
    }
    return loading;
  }

  get pageTitle(): string {
    if (this.editing) {
      return "Edit Listing";
    } else {
      return "Create Listing";
    }
  }

  openPromotionDialog(): void {
    var data: any = {}
    if (this.promotionPriceCents) {
      data.price = this.promotionPriceCents / 100.0;
    }
    if (this.promotionType) {
      data.type = this.promotionType;
    }

    const dialogRef = this.dialog.open(EditListingPromotionDialog, {
      width: '500px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.price) {
          this.promotionPriceCents = result.price * 100.0
        } else {
          this.promotionPriceCents = null
        }
        this.promotionType = result.type
      }
    });
  }

  clearPromotion(): void {
    this.promotionPriceCents = null
    this.promotionType = null
  }

  openIssueDialog(): void {
    const dialogRef = this.dialog.open(CreateIssueDialog, {
      width: '500px',
      data: this.issues,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.issueResponses.push(result);
      }
    });
  }

  openCreateTagDialog(): void {
    const dialogRef = this.dialog.open(CreateTagDialog, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.tagsService.createTag(result).subscribe(createResult => {
          this.allTags.set(createResult.id, createResult);
          if (this._listing != null) {
            this.availableTags = this.calculateAvailableTags(this.allTags, this._listing.tags);
          }
        })
      }
    });
  }

  onSearchTextChanged(query: string) {
    if (query.length < 2) {
      this.searchResults.next([]);
      return;
    }

    this.modelsService.searchModels(query).subscribe({
      next: models => {
        this.searchResults.next(models);
      },
      error: error => {
        console.log(error)
      }
    })
  }

  onSearchResultClicked(result: Model) {
    this.selectedModel = result.id;
    this.modelText = `${result.brand.displayName ?? result.brand.name} ${result.displayName ?? result.name}`;
    this.hideSearchResults = true;
    this.searchResults.next([]);
  }

  onFocus() {
    this.searchBarFocused = true;
    this.calculateSearchResultVisibilityState();
  }

  onBlur() {
    this.searchBarFocused = false;
    this.calculateSearchResultVisibilityState();
  }

  mouseEnterSearchResults(result: Model) {
    this.mouseHoveringOverResults = true;
    this.hoveredModel = result
    this.calculateSearchResultVisibilityState();
  }

  mouseExitSearchResults() {
    this.mouseHoveringOverResults = false;
    this.hoveredModel = null
    this.calculateSearchResultVisibilityState();
  }

  private calculateSearchResultVisibilityState() {
    this.hideSearchResults = (!this.mouseHoveringOverResults && !this.searchBarFocused);
  }

  onEnterPressed(): void {
    if (String(this.modelText) !== this.modelText) {
        return
    }

    var modelId = Number(this.modelText);
    if (isNaN(modelId)) {
        return
    }

    this.modelsService.getModel(modelId).subscribe({
        next: model => {
          if (model) {
            this.selectedModel = model.id;
            this.modelText = `${model.brand.displayName ?? model.brand.name} ${model.displayName ?? model.name}`;
            this.hideSearchResults = true;
            this.searchResults.next([]);
          }
        },
        error: error => {
            console.log(error);
        }
    })
}

  clearModelSelection = () => {
    this.selectedModel = null;
    this.modelText = null;
  }

  displayCondition = (condition: string) => {
    return capitalize(replace(condition, /_/g, ' ').toLowerCase());
  };

  setOriginalOwner = (checked: boolean) => {
    this.originalOwner = checked;
  };

  getIssueById = (id: number) => {
    return this.issues.find(i => i.id == id);
  };

  calculateAvailableTags(allTags: Map<Number, Tag>, listingTags: Tag[]): Tag[] {
    let groupData: Map<Number, Boolean> = new Map();
    for (let t of allTags.entries()) {
      if (t[1].groupId != null && !groupData.has(t[1].groupId)) {
        groupData.set(t[1].groupId, t[1]?.isRepeatable ?? false);
      }
    }
    let lts: Set<Number> = new Set(listingTags.map(t => t.id));
    let foundGroups: Set<Number> = new Set(listingTags.map(t => t.groupId ?? -1));

    let rv: Tag[] = [];
    for (let t of allTags.entries()) {
      if (lts.has(t[0])) {
        continue;
      }
      if (t[1].groupId != null && !t[1].isRepeatable && foundGroups.has(t[1].groupId)) {
        continue;
      }
      if (t[1].stableKey != null) {
        continue;
      }
      rv.push(t[1]);
    }
    return rv;
  }

  addTag() {
    if (this._listing == null) {
      return;
    }

    this.tagsChanged = true;

    if (this.selectedTag != undefined) {
      let selected = this.allTags.get(this.selectedTag.value);
      if (selected != undefined) {
        this._listing?.tags.push(selected);
      }
    }
    this.availableTags = this.calculateAvailableTags(this.allTags, this._listing.tags);
  }

  removeTag(id: Number) {
    if (this._listing == null) {
      return;
    }

    let idx = this._listing.tags.findIndex(t => t.id == id);
    if (this._listing.tags.at(idx)?.stableKey != null) {
      // Can't remove platform owned tags
      return;
    }

    this.tagsChanged = true;

    this._listing.tags.splice(idx, 1);
    this.availableTags = this.calculateAvailableTags(this.allTags, this._listing.tags);
  }

  removeIssueResponse = (issue: IssueResponse) => {
    remove(this.issueResponses, i => i == issue);
  };

  presentImageKey = (key: string) => {
    return capitalize(key.replace(/_/g, " ").toLowerCase());
  }

  onSelectSeller = (seller: SellerProfile) => {
    this.selectedSeller.setValue(seller);
  };

  calculateListedPriceCents(): number {
    if (this.price.value == null) {
      // Should be impossible as this is checked in the caller
      return 0;
    }
    var price = parseFloat(this.price.value);
    if (this.ingestionRequest && this.selectedSeller.value?.markupExternalListings) {
      // Markup the price of the listing based on the seller's current markup settings.
      var seller = this.selectedSeller.value
      var commissionRateBips;
      var sellerFeeCents;
      if (seller.externalListingsMarkupBips) {
        commissionRateBips = seller.externalListingsMarkupBips;
        sellerFeeCents = 0;
      } else {
        commissionRateBips = seller.overrideCommissionRateBips ? seller.overrideCommissionRateBips : this.sellerLevel.commissionRateBips;
        sellerFeeCents = seller.overrideSellerFeeCents ? seller.overrideSellerFeeCents : this.sellerLevel.sellerFeeCents;
      }

      price = (price + (sellerFeeCents / 100)) / (1 - (commissionRateBips / 10000))
    }
    return (Math.ceil(price / 5) * 5) * 100 // round up to nearest 5 + convert to cents
  }

  isSaveableListing(): boolean {
    if (!this.selectedSeller.valid || !this.selectedModel || !this.condition.valid) {
      return false;
    }

    if (this.pricingModel.getRawValue() == "FIXED_PRICE" && (!this.price.valid)) {
      return false;
    }
    return true;
  }

  isCompleteListing(): boolean {
    if (!this.isSaveableListing()) {
      return false;
    }

    if (!this.imageGrid) {
      return false;
    }

    if(!this.imageGrid.getItemForSlot('FRONT')?.image) {
      return false;
    }

    return true;
  }

  onSubmit = (addAnother: boolean, saveAsDraft: boolean = false) => {
    this.submitting = true;
    if (this.selectedSeller.value == null || this.price.value == null) {
      return;
    }
    var params: any = {
      sellerProfile: this.selectedSeller.value.id,
      model: this.selectedModel,
      manufactureMonth: this.manufactureMonth.value,
      manufactureYear: this.manufactureYear.value,
      purchaseMonth: this.purchaseMonth.value,
      purchaseYear: this.purchaseYear.value,
      inventoryNumber: this.inventoryNumber.value,
      condition: this.condition.value,
      accessories: this.selectedAccessories.value,
      isOriginalOwner: this.originalOwner,
      externalSyncEnabled: this.externalSyncEnabled.value,
      externalPriceSyncEnabled: this.externalPriceSyncEnabled.value,
      autoDuplicateOnSale: this.autoDuplicateOnSale.value,
      issues: this.issueResponses.map(i => { return { issue: i.issue.id, answer: i.answer }; })
    };

    if (this.pricingModel.value) {
      params.activePricingModel = this.pricingModel.value;
    }

    if (this.pricingModel.value == 'FIXED_PRICE') {
      params.fixedPriceInfo = {
        priceCents: this.calculateListedPriceCents(),
        offersAllowed: this.offersAllowed.value,
      }

      if (this.autoDeclineOffersUnderCents.value) {
        params.fixedPriceInfo.autoDeclineOffersUnderCents = Number.parseInt(this.autoDeclineOffersUnderCents.value) * 100;
      }

      if (this.promotionPriceCents || this.promotionType) {
        if (this._listing?.promotion?.priceCents != this.promotionPriceCents) {
          if (!params.fixedPriceInfo.promotion) {
            params.fixedPriceInfo.promotion = {}
          }
          params.fixedPriceInfo.promotion.priceCents = this.promotionPriceCents
        }
        if (this._listing?.promotion?.type != this.promotionType) {
          if (!params.fixedPriceInfo.promotion) {
            params.fixedPriceInfo.promotion = {}
          }
          params.fixedPriceInfo.promotion.type = this.promotionType
        }
      } else if (this._listing?.promotion) {
        params.fixedPriceInfo.promotion = null
      }
    } else if (this.pricingModel.value == 'AUCTION') {
      let tmpDisclaimers : string[] = [];
      for (let disc of this.disclaimerOptions.keys()) {
        if (this.disclaimerOptions.get(disc)) {
          tmpDisclaimers.push(disc);
        }
      }
      params.auctionInfo = {
        startDate: this.auctionStartDate.value?.toUTCString(),
        endDate: this.auctionEndDate.value?.toUTCString(),
        estimatedValueRange: {
          lowerBoundCents: this.auctionEstimateLowerBound.value ? this.auctionEstimateLowerBound.value  * 100 : null,
          upperBoundCents: this.auctionEstimateUpperBound.value ? this.auctionEstimateUpperBound.value  * 100 : null
        },
        disclaimers: tmpDisclaimers,
        essay: this.lotEssay.value
      }

      if (this.priceGuaranteeType.value === "RESERVE") {
        params.auctionInfo.reserveCents = this.reserveDollars?.value ? this.reserveDollars?.value * 100 : null;
      } else if (this.priceGuaranteeType.value === "GUARANTEED_BID") {
        params.auctionInfo.guarantorBuyerProfileId = this.guarantor?.value?.id;
        params.auctionInfo.guaranteedBidCents = this.guaranteedBidDollars?.value ? this.guaranteedBidDollars?.value * 100 : null;
      } else {
        params.auctionInfo.reserveCents = null;
        params.auctionInfo.guarantorBuyerProfileId = null;
        params.auctionInfo.guaranteedBidCents = null;
      }
    }

    if (this.externalUrl.value && this.externalUrl.value.length > 0) {
      params.externalUrl = this.externalUrl.value
    } else {
      params.externalUrl = null
    }

    if (this.desiredNetPayoutCents.value) {
      params.desiredNetPayoutCents = this.desiredNetPayoutCents.value;
    }
    if (this.sellerNotes.value) {
      params.sellerNotes = this.sellerNotes.value;
    }

    if (this._listing) {
      if (this._listing.status === 'DRAFT' && !saveAsDraft) {
        params.status = 'PENDING_REVIEW'
      }
    } else {
      params.status = saveAsDraft ? 'DRAFT' : 'PENDING_REVIEW'
    }
    if (this.tagsChanged && this._listing != null) {
      params.tags = this._listing.tags.map(t => t.id);
    }

    if (this.editing) {
      this.updateListing(params);
    } else {
      this.createListing(addAnother, params);
    }
  }

  onCancel = () => {
    if (this._hasPreviousRoute) {
      this.location.back();
    } else {
      if (this.editing) {
        this.router.navigate(['/marketplace', 'listings', this._listingId]);
      } else {
        this.router.navigate(['/marketplace', 'listings']);
      }
    }
  }

  createListing = (addAnother: boolean, params: any) => {
    this.registerImages(this.imageGrid.finalize()).subscribe({
      next: (registeredImages) => {
        if (this.selectedSeller.value == null) {
          return;
        }

        var listingChallenge: ListingChallenge;
        this.listingsService.getChallenge(this.selectedSeller.value.id).subscribe({
          next: (challenge: ListingChallenge) => {
            listingChallenge = challenge;

            let imagesObjs: any[] = [];
            for (let entry of registeredImages.entries()) {
              imagesObjs.push({type: entry[0], image: entry[1].savedImageId});
            }

            var createParams = {
              ...params,
              listingChallenge: challenge.id,
              images: imagesObjs
            };
            this.listingsService.createListing(createParams).subscribe({
              next: () => {
                this.submitting = false;
                if (addAnother) {
                  window.location.reload();
                } else {
                  this.router.navigate(['/marketplace', 'listings']);
                }
              },
              error: response => {
                console.log(response.error);
                this.showError(response.error);
                this.submitting = false;
              }
            });
          },
          error: response => {
            console.log(response.error);
            this.showError(response.error);
            this.submitting = false;
          }
        });
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  };

  updateListing = (params: any) => {
    this.registerImages(this.imageGrid.finalize()).subscribe({
      next: (registeredImages) => {
        if (!registeredImages) {
          this.showError({error: "Failed to upload images"})
          return
        }

        let imagesObjs: any[] = [];
        let registeredImageTypes = new Set<string>();
        for (let entry of registeredImages.entries()) {
          imagesObjs.push({type: entry[0], image: entry[1].savedImageId});
          registeredImageTypes.add(entry[0]);
        }
        if (this._listing != null && imagesObjs.length != this._listing?.images.length) {
          for (let existingImage of this._listing.images) {
            if (!registeredImageTypes.has(existingImage.type)) {
              imagesObjs.push({type: existingImage.type, image: null});
            }
          }
        }

        var updateParams = {
          ...params,
          images: imagesObjs,
        };
        this.listingsService.updateListing(this._listingId, updateParams).subscribe({
          next: () => {
            this.submitting = false;
            this.router.navigate(['/marketplace', 'listings', this._listingId]);
          },
          error: (response) => {
            console.log(response.error);
            this.showError(response.error);
            this.submitting = false;
          }
        });
      },
      error: response => {
        console.log(response.error);
        this.showError(response.error);
        this.submitting = false;
      }
    })
  }

  private registerImages(imageMetadata: Map<string, ImageMetadata>): Observable<Map<string, ImageMetadata>> {
    let imagesToRegister : ImageMetadata[] = [];
    for (let metadata of imageMetadata.values()) {
      if (metadata.state == "UPLOADED") {
        imagesToRegister.push(metadata);
      }
    }

    return this.imageService.registerImages(imagesToRegister).pipe(map(response => {
      let idx = 0;
      let result : Map<string, ImageMetadata> = new Map();
      for (let type of imageMetadata.keys()) {
        let existingMetadata = imageMetadata.get(type);
        if (existingMetadata != undefined && existingMetadata.state == "UPLOADED") {
          let registeredMetadata = response[idx];
          idx++;
          registeredMetadata.state = "REGISTERED";
          result.set(type, registeredMetadata);
        } else if (existingMetadata != undefined && existingMetadata.state == "REGISTERED") {
          result.set(type, existingMetadata);
        }
      }
      return result;
    }));
  }

  private showError(error: {error: string, description?: string }) {
    console.log(`Showing dialog for error: ${error}`)
    this.dialog.open(ErrorDialog,  {
      width: '500px',
      data: {
        error: error
      }
    });
  }

  private updateDisclaimer(name: string, val: boolean) {
    this.disclaimerOptions.set(name, val);
  }

  get canEnableReserve(): boolean {
    if (!!this.priceGuaranteeType.value) {
      // We'll allow you to switch from the GB to the auction type.
      return true;
    }
    // But if the guaranteed bid disclaimer is explicitly set you have to disable it first to switch to reserve.
    if (this.disclaimerOptions.get("GUARANTEED_BID")) {
      return false;
    }
    return true;
  }

  get canEditGuaranteedBidDiclaimer(): boolean {
    if (!!this.priceGuaranteeType.value) {
      return false;
    }
    return true;
  }
}

@Component({
  selector: 'edit-listing-promotion-dialog',
  templateUrl: './edit-listing-promotion-dialog.component.html'
})
export class EditListingPromotionDialog {
  price = new FormControl();
  type = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<EditListingPromotionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.price) {
      this.price.setValue(data.price)
    }
    if (data.type) {
      this.type.setValue(data.type)
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close({
      price: this.price.value,
      type: this.type.value,
    });
  }
}

@Component({
  selector: 'create-issue-dialog',
  templateUrl: './create-issue-dialog.component.html'
})
export class CreateIssueDialog {
  category = new FormControl('');
  issueForCategory = new FormControl();
  answer = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<CreateIssueDialog>,
    @Inject(MAT_DIALOG_DATA) public issues: IssueTemplate[]
  ) {}

  get categoryOptions() {
    return [...new Set(this.issues.map(i => i.category))];
  }

  get issuesForCategory() {
    return this.issues.filter(i => i.category === this.category.value);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (!this.answer.value && this.issueForCategory.value.answerRequired) {
      return;
    }

    var issueResponse = new IssueResponse();
    issueResponse.issue = { id: this.issueForCategory.value.id };
    issueResponse.answer = this.answer.value;

    this.dialogRef.close(issueResponse);
  }
}

  @Component({
    selector: 'create-tag-dialog',
    templateUrl: './create-tag-dialog.component.html'
  })
  export class CreateTagDialog {
    tagDisplayValue = new FormControl('');

    constructor(
      public dialogRef: MatDialogRef<CreateTagDialog>
    ) {}

    onCancel(): void {
      this.dialogRef.close();
    }

    onSubmit(): void {
      if (!this.tagDisplayValue.value) {
        return;
      }

      this.dialogRef.close(this.tagDisplayValue.value);
    }
}
