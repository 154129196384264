export class ImageMetadata {
  uploadUri?: string;
  filename?: string;
  filetype?: string;
  height?: number;
  width?: number;
  savedImageId?: number;
  state!: string;

  get rawUrl() {
    return this.uploadUri?.split("?")[0] ?? "";
  }

  public constructor() {
    this.state = "EMPTY";
  }
}

export default class Image {
  id!: number;
  height!: number;
  width!: number;
  url!: string;
  rawUrl!: string;
  optimizedUrl!: string;
}

export const imgState : string[] = ["EMPTY", "UPLOADING", "UPLOADED", "REGISTERED"];
