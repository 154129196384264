<div class="listing-image-grid">
    <div style="padding-left: 20px; display: flex; flex-direction: row;">
        <div style="padding-right: 10px;">Select Images, Drag to Reassign</div>
        <input name="loadimages" type="file" (change)="fileChangeEvent($event)" multiple/>
    </div>
    <div class="image-grid-images">
        <div *ngFor="let i of imageData.keys()">
            <image-grid-slot [slotData]="imageData.get(i)" [itemData]="getItemForSlot(i)">
            </image-grid-slot>
        </div>
    </div>
</div>