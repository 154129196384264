<div class="d-flex flex-column h-100">
    <mat-card class="m-2">
      <mat-card-content class="d-flex flex-md-row flex-column">
        <mat-form-field appearance="outline" class="flex-fill mx-1">
          <mat-label>Status</mat-label>
          <mat-select [(ngModel)]='statusFilter' name='Status' (selectionChange)="onSelectStatus($event)">
            <mat-option *ngFor="let status of offerStatuses" [value]="status">
              {{allStatuses[status]}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <listing-select label="Listing" placeholder="Pick a listing" [control]="listingFilterControl" class="flex-fill mx-1"></listing-select>
        <buyer-select [control]="buyerFilterControl" class="flex-fill mx-1"></buyer-select>
        <mat-form-field appearance="outline" class="flex-fill mx-1">
          <mat-label>Version</mat-label>
          <mat-select [(ngModel)]='versionFilter' name='Version' (selectionChange)="onSelectVersion($event)">
            <mat-option *ngFor="let version of offerVersions" [value]="version">
              V{{version}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill mx-1">
          <mat-label>Client</mat-label>
          <mat-select [(ngModel)]='clientFilter' name='Client' (selectionChange)="onSelectClient($event)">
              <mat-option [value]="">-</mat-option>
              <mat-option *ngFor="let item of clientNames | keyvalue" [value]="item.key">
                  {{ item.value }}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill mx-1">
          <mat-label>Accepted</mat-label>
          <mat-select [(ngModel)]='acceptedFilter' name='Accepted' (selectionChange)="onSelectAccepted($event)">
              <mat-option [value]="">-</mat-option>
              <mat-option [value]="'true'">Accepted or Was Accepted</mat-option>
              <mat-option [value]="'false'">Never Accepted</mat-option>
          </mat-select> 
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <mat-card class="mx-2 mb-2 p-0 flex-fill d-flex flex-column overflow-scroll">
      <div class="flex-fill position-relative overflow-scroll">
        <div *ngIf="isLoading" class="w-100 h-100 position-absolute d-flex align-items-center justify-content-center" style="z-index: 1;">
          <div *ngIf="offers.length > 0" class="w-100 h-100 position-absolute" style="background-color: rgba(0, 0, 0, 0.1);"></div>
          <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
        </div>
        <div class="h-100 w-100 overflow-scroll">
          <table mat-table matSort (matSortChange)="onSort($event)" [dataSource]="offers" class="w-100">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> Id </th>
              <td mat-cell *matCellDef="let offer"> {{offer.id}} </td>
            </ng-container>
            <ng-container matColumnDef="version">
              <th mat-header-cell *matHeaderCellDef> Version </th>
              <td mat-cell *matCellDef="let offer"> 
                <div class="badge-narrow">
                  V{{offer.apiVersion}} 
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let offer"> 
                <div class="badge-narrow" [ngClass]="{
                  'badge-narrow-success': offer.status === 'ORDERED', 
                  'badge-narrow-error': offer.status === 'CANCELLED' || offer.status === 'DECLINED' || offer.status === 'EXPIRED'
                  }">
                  {{offer.displayStatus | uppercase}} 
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="listing">
              <th mat-header-cell *matHeaderCellDef> Listing </th>
              <td mat-cell *matCellDef="let offer">
                <listing-chip [listing]="offer.listing"></listing-chip>
              </td>
            </ng-container>
            <ng-container matColumnDef="priceCents">
              <th mat-header-cell *matHeaderCellDef> Price </th>
              <td mat-cell *matCellDef="let offer"> {{(offer.priceCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}} </td>
            </ng-container>
            <ng-container matColumnDef="buyer">
              <th mat-header-cell *matHeaderCellDef> Buyer </th>
              <td mat-cell *matCellDef="let offer"><buyer-chip [buyer]="offer.buyerProfile"></buyer-chip></td>
            </ng-container>
            <ng-container matColumnDef="created">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created"> Created </th>
              <td mat-cell *matCellDef="let offer"> {{offer.displayCreated}} </td>
            </ng-container>
            <ng-container matColumnDef="client">
              <th mat-header-cell *matHeaderCellDef> Client </th>
              <td mat-cell *matCellDef="let offer">
                <mat-chip-list>
                  <mat-chip *ngIf="offer.client" [routerLink]="['/marketplace/offers']" [queryParams]="{ client: offer.client }" style="font-size: 9pt !important">{{ clientNames[offer.client] }}</mat-chip>
                </mat-chip-list>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let offer; columns: displayedColumns;" [routerLink]="['/marketplace/offers/', offer.id]">
          </table>
        </div>
      </div>
      <mat-paginator [length]="length"
                  [pageIndex]="page"
                  [pageSize]="pageSize"
                  [pageSizeOptions]="pageSizeOptions"
                  (page)="pageChangeEvent($event)"
                  aria-label="Select page">
      </mat-paginator> 
    </mat-card>  
  </div>
  