import { Component, Inject, OnDestroy, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

export interface DialogData {
  image: string,
  target: string,
  onClickSave: (image: string, target: string, format: string, width: number, height: number) => void
}

@Component({
  selector: 'image-grid-cropper',
  templateUrl: './image-grid-cropper.component.html',
})
export class ImageGridCropper implements OnInit, OnDestroy {
  croppedImage: any = '';

  @ViewChild(ImageCropperComponent) private imageCropper!: ImageCropperComponent;

  constructor(
    public dialogRef: MatDialogRef<ImageGridCropper>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  imageCropped(event: ImageCroppedEvent) {}
  
  imageLoaded() {}
  
  cropperReady() {}

  loadImageFailed() {
    console.log('load image failed');
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    let crop = this.imageCropper.crop('base64');
    this.croppedImage = crop?.base64 ?? "";

    this.data.onClickSave(this.croppedImage, this.data.target, this.imageCropper.format, crop?.width ?? 0, crop?.height ?? 0);
    this.dialogRef.close();
  }
}