import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import Shipment from '../models/shipment.model';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsService {
  constructor(private http: HttpClient) { }

  public createShipment(params: any): Observable<Shipment> {
    return this.http.post<Shipment>(environment.apiUrl + `/shipping/shipments`, params).pipe(
      map(fetchedShipment => this.toShipment(fetchedShipment))
    );
  }

  public markAsReceived(shipmentId: number) {
    return this.http.post(environment.apiUrl + `/shipping/shipments/${shipmentId}/mark-received`, {});
  }

  public markAsInTransit(shipmentId: number) {
    return this.http.post(environment.apiUrl + `/shipping/shipments/${shipmentId}/mark-in-transit`, {});
  }

  private toShipment(fetchedShipment: any): Shipment {
    var shipment = Object.assign(new Shipment(), fetchedShipment);
    return shipment;
  }
}
