import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ImageMetadata } from 'src/app/models/image.model';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(
    private httpClient: HttpClient
  ) { }

  public getUploadUrl(fileType: string): Observable<ImageMetadata> {
        var path = '/image/images/prepare?type=' + fileType;
        return this.httpClient.get<any>(environment.apiUrl + path, {observe: 'response'}).pipe(
          map(response => {return response.body;}));
  }

  public uploadImageToUrl(metadata: ImageMetadata, image: Blob): Observable<boolean> {
    if (!image) {
      throw new Error("Can't upload null image");
    }
    if (!metadata || !metadata.uploadUri) {
      throw new Error("Missing image metadata");
    }

    return new Observable(function (observer) {
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            observer.next(true);
          } else {
            observer.error(false);
          }
        }
      };
      xhr.open("PUT", metadata.uploadUri ?? "", true);
      xhr.setRequestHeader("content-type", 'application/octet-stream');
      xhr.setRequestHeader("x-goog-resumeable", "start");

      xhr.send(image);
    });
  }

  public registerImages(images: ImageMetadata[]): Observable<ImageMetadata[]> {
    if (images.length == 0) {
      return new Observable(function(observer) {observer.next([])});
    }

     let entries = images.map(im => {
       return {
           filename: im.filename,
           fileType: im.filetype,
           width: im.width,
           height: im.height
      }});
    let body = {
      imageTemplates: entries
    }

     return this.httpClient.post<any[]>(environment.apiUrl + '/image/images/register', body, {observe: 'response'})
        .pipe(
          map(response => {
            if (!response.body) {
              return [];
            }
            for (let i = 0; i < response.body.length; i++) {
               images[i].savedImageId = response.body[i].id;
               images[i].state = "REGISTERED";
            }
            return images;
          })
        );
  }

  private toImage(fetchedImage: any): typeof Image {
    return Object.assign(new Image(), fetchedImage);
  }

  public uploadImage(image: InstanceType<typeof Image> | null): Observable<number> {
    if (!image) {
      return throwError("Can't upload null image");
    }

    var imageParts = image.src.split(',');
    if (imageParts.length != 2) {
      return throwError("Something is wrong with the image");
    }
    var imageFormat: string;
    if (imageParts[0].includes('image/png')) {
      imageFormat = "PNG";
    } else if (imageParts[0].includes('image/jpeg')) {
      imageFormat = "JPEG";
    } else {
      return throwError(`Unsupported Image Format: ${imageParts[0]}`);
    }

    var body = {
        width: image.naturalWidth,
        height: image.naturalHeight,
        data: imageParts[1],
        dataFormat: imageFormat
    }
    return this.httpClient.post<any>(environment.apiUrl + '/image/images', body, {observe: 'response'}).pipe(
      map(response => {
        const locationHeader = response.headers.get('location');
        if (locationHeader == null) {
          throw new Error('Failed to parse location header for created item')
        }
        return parseInt(locationHeader.split('/').slice(-1)[0]);
      })
    );
  }

  public deleteImage(url: string) {
    console.log("Delete " + url);
    // TODO(add server support for this)
  }
}
