import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ToolbarService, ToolbarController, TitlePathElement } from 'src/app/_services/toolbar.service';
import { MatDialog } from '@angular/material/dialog';
import IngestionRequest, { allReasons } from 'src/app/models/ingestion-request.model';
import { IngestionRequestsService } from 'src/app/_services/ingestion-requests.service';
import { DeleteDialog } from 'src/app/common/delete-dialog/delete-dialog.component';
import User from 'src/app/models/console/user.model';
import { UsersService } from 'src/app/_services/console/users.service';
import { AuthorizationService } from 'src/app/_services/authorization.service';

@Component({
  selector: 'app-ingestion-request-detail',
  templateUrl: './ingestion-request-detail.component.html',
  styleUrls: ['./ingestion-request-detail.component.css']
})
export class IngestionRequestDetailComponent implements OnInit, OnDestroy, ToolbarController {
  titlePath = new BehaviorSubject<TitlePathElement[]>([{ title: "Listing Requests", path: ['/marketplace', 'ingestion-requests'] }]);
  title = new BehaviorSubject<string>("?");

  ingestionRequest!: IngestionRequest | null;
  _ingestionRequestId: number;

  consoleUsers!: User[];

  flagging = false
  archiving = false

  flagReasonSelector: string | null = null;

  private _destroyed = new BehaviorSubject<boolean>(false);

  constructor(
    public dialog: MatDialog,
    private toolbarService: ToolbarService,
    private route: ActivatedRoute,
    private ingestionRequestsService: IngestionRequestsService,
    private router: Router,
    private userService: UsersService,
    private authorizationService: AuthorizationService,
  ) {
    const routeParams = this.route.snapshot.paramMap;
    this._ingestionRequestId = Number(routeParams.get('ingestionRequestId'));
    this.title.next(this._ingestionRequestId.toString());

    this.fetchIngestionRequest();

    this.authorizationService.initIfNeeded(() => {
      console.log(this.authorizationService.getAuthenticatedUserUuid())
      this.userService.getUsers().subscribe({
        next: users => {
          this.consoleUsers = users.sort((a, b) => {
            if (a.uuid === this.authorizationService.getAuthenticatedUserUuid()) {
              return -1;
            } else if (b.uuid === this.authorizationService.getAuthenticatedUserUuid()) {
              return 1;
            }

            const nameA = a.displayName.toUpperCase();
            const nameB = b.displayName.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
        }
      });
    })
  }

  private fetchIngestionRequest() {
    this.ingestionRequest = null
    this.ingestionRequestsService.getIngestionRequest(this._ingestionRequestId).subscribe({
      next: (ingestionRequest: IngestionRequest) => {
        this.ingestionRequest = ingestionRequest;
        console.log(this.ingestionRequest)
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  ngOnInit(): void {
    this.toolbarService.setController(this);
  }

  ngOnDestroy(): void {
    this.toolbarService.removeController(this);
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  onSelectNewAssignee(assignee: User | null) {
    this.ingestionRequestsService.updateIngestionRequest(this._ingestionRequestId, { assignee: assignee?.uuid ?? null }).subscribe({
      next: () => {
        this.fetchIngestionRequest()
      },
      error: error => {
        console.log(error);
      }
    });
  }

  onEdit(): void {
    this.router.navigate(['marketplace', 'ingestion-requests', this._ingestionRequestId, 'edit']);
  }

  onFlag(flagged: boolean): void {
    this.flagging = true
    let params: any = {
      flagged: flagged
    }
    if (flagged) {
      params.flaggedReason = this.flagReasonSelector;
    }

    this.ingestionRequestsService.updateIngestionRequest(this._ingestionRequestId, params).subscribe({
      next: () => {
        this.flagging = false
        this.fetchIngestionRequest()
      },
      error: error => {
        console.log(error);
        this.flagging = false
      }
    });
  }

  onArchive(): void {
    this.dialog.open(DeleteDialog, {
      width: '20vw',
      data: {name: ' (archive) this listing request', onClickConfirm: (callback: () => void) => this.archiveIngestionRequest(callback)}
    })
  }

  getFlaggedReason(): string {
    if (this.ingestionRequest != null && this.ingestionRequest.flaggedReason != null) {
      return allReasons[this.ingestionRequest?.flaggedReason];
    }
    return "Reason Not Provided";
  }

  public archiveIngestionRequest(callback: () => void) {
    this.archiving = true
    this.ingestionRequestsService.archiveIngestionRequest(this._ingestionRequestId).subscribe({
      next: () => {
        this.archiving = false
        this.ingestionRequest = null
        callback()
        this.fetchIngestionRequest()
      },
      error: (error: any) => {
        this.archiving = false
        console.log(error);
        callback()
      }
    });
  }
}
