import { Component, Inject, OnDestroy, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage, base64ToFile } from 'ngx-image-cropper';
import { Observable, throwError } from 'rxjs';
import { ImageService } from 'src/app/_services/image.service';
import { ImageMetadata } from 'src/app/models/image.model';

export interface DialogData {
  image_type: string,
  onUploadStart: (type: string) => void,
  onUploadFinished: (type: string, metadata: ImageMetadata | null) => void
}

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.component.html',
})
export class ImageUploadDialog implements OnInit, OnDestroy {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  uploadData?: ImageMetadata;

  @ViewChild(ImageCropperComponent) private imageCropper!: ImageCropperComponent;

  constructor(
    public dialogRef: MatDialogRef<ImageUploadDialog>,
    private imageService: ImageService,
    @Inject(MAT_DIALOG_DATA) public inputData: DialogData
  ) {}

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    console.log("image cropped");
  }
  imageLoaded() {
    console.log('loaded image');
  }
  cropperReady() {
    console.log('cropper ready');
  }
  loadImageFailed() {
    console.log('load image failed');
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSave() : void {
    this.imageService.getUploadUrl(this.imageCropper.format).subscribe({
      next: response => {
        let metadata = Object.assign(new ImageMetadata(), response);

        if (metadata.filename?.includes('.png')) {
          metadata.filetype = "png";
        } else if (metadata.filename?.includes('.jpeg')) {
          metadata.filetype = "jpeg";
        } else {
          throw new Error(`Unsupported Image Format: ${metadata.filename}`);
        }
        metadata.filename = metadata.filename.split(".")[0];

        this.inputData.onUploadStart(this.inputData.image_type);
        this.dialogRef.close();
        this.receiveUrl(metadata);
      }
    });
  }

  private receiveUrl(uploadData: ImageMetadata): void {
    let crop = this.imageCropper.crop('base64');
    this.croppedImage = base64ToFile(crop?.base64 ?? "");
    if (uploadData) {
      uploadData.height = crop?.height;
      uploadData.width = crop?.width;
      let uploadResult = this.imageService.uploadImageToUrl(uploadData, this.croppedImage);
      uploadResult.subscribe(ok => {
        uploadData.state = "UPLOADED";
        this.inputData.onUploadFinished(this.inputData.image_type, uploadData);
      });
    } else {
      console.log("Failed to get upload URL");
      this.inputData.onUploadFinished(this.inputData.image_type, null);
    }
  }
}
