<div class="dialog-content">
    <div class="title">Delete user</div>
    <div class="break"></div>
    <p>Are you sure you want to delete this user?</p>

    <mat-card *ngIf="errorMsg" appearance="outlined" style="margin-top: 25px;">
        <mat-card-content>
            <p class="error-msg">{{errorMsg}}</p>
        </mat-card-content>
    </mat-card>

    <div class="d-flex justify-content-between" style="margin-top: 25px;">
        <button mat-raised-button color="primary" (click)="onCancel();">Cancel</button>
        <button mat-raised-button color="warn" (click)="onDelete();">Delete</button>
    </div>
</div>
