<div class="container" *ngIf="order else loading">
  <div class="w100 d-flex flex-row justify-content-center">
    <div style="width: 100%; max-width: 800px;">
      <mat-card class="m-2">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span class="d-flex justify-content-between align-items-center" style="gap: 10px">
              <mat-icon color="warn" *ngIf="order.flagged">flag</mat-icon>
              <span>General Information</span>
              <mat-chip-listbox>
                <mat-chip *ngIf="order.fromAuction"><mat-icon matChipAvatar>bolt</mat-icon>Auction</mat-chip>
                <mat-chip *ngIf="order.client" [routerLink]="['/marketplace/orders']" [queryParams]="{ client: order.client }">{{ clientNames[order.client] }}</mat-chip>
              </mat-chip-listbox>
            </span>
            <span>
              <button class="m-1" *ngIf="!order.flagged" (click)="onFlag(true)" mat-raised-button color="warn" [disabled]="isPerformingAction()" [class.spinner]="isFlagging">Flag</button>
              <button class="m-1" *ngIf="order.flagged"  (click)="onFlag(false)" mat-raised-button color="primary" [disabled]="isPerformingAction()" [class.spinner]="isFlagging">Unflag</button>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="mb-2">
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>{{order.status}}<ng-container *ngIf="order.status=='CANCELLED'"> / {{order.cancellationReason}}</ng-container></span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Created
              </div>
              <span>{{order.created | date:'medium' }}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Updated
              </div>
              <span>{{order.updated | date:'medium'}}</span>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer class="p-2 pt-0">
              <button mat-raised-button color="warn" class="ms-2 mb-2" (click)="cancel()" [disabled]="isPerformingAction() || !order.canCancel" [class.spinner]="isCancelling">Cancel</button>
        </mat-card-footer>
      </mat-card>
      <mat-card class="m-2" *ngIf="order.invoice.paymentType !== 'WIRE'">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span class="d-flex justify-content-between align-items-center" style="gap: 10px">
              <span>Payment Details</span>
            </span>
            <span>
              <button class="leaveButton" *ngIf="order.paymentTransaction.stripeDashboardLink" (click)="onViewPaymentInStripe()" mat-stroked-button>
                View in Stripe
                <mat-icon iconPositionEnd>exit_to_app</mat-icon>
              </button>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="mb-1">
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>{{order.paymentTransaction.status}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Total
              </div>
              <span>{{(order.invoice.totalPriceCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Credits Applied
              </div>
              <span>{{order.invoice.creditsApplied}} ({{(order.invoice.creditsApplied/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}})</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Amount Due
              </div>
              <span>{{(order.invoice.paymentAmountCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
            </div>
            <div class="labeled-box" *ngIf="order.invoice.paymentAmountCents > 0">
              <div class="label">
                Payment Type
              </div>
              <span>{{order.paymentTransaction.type}}</span>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2" *ngIf="order.holdTransaction">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span class="d-flex justify-content-between align-items-center" style="gap: 10px">
              <span>Order Hold</span>
            </span>
            <span>
              <button class="leaveButton" *ngIf="order.holdTransaction.stripeDashboardLink" (click)="onViewHoldInStripe()" mat-stroked-button>
                View in Stripe
                <mat-icon iconPositionEnd>exit_to_app</mat-icon>
              </button>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="mb-1">
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>{{order.holdTransaction.status}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Total
              </div>
              <span>{{(order.holdTransaction.baseAmountCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Credits Applied
              </div>
              <span>{{order.holdTransaction.creditsApplied}} ({{(order.holdTransaction.creditsApplied/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}})</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Amount Due
              </div>
              <span>{{(order.holdTransaction.paymentAmountCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
            </div>
            <div class="labeled-box" *ngIf="order.holdTransaction.paymentAmountCents > 0">
              <div class="label">
                Payment Type
              </div>
              <span>{{order.holdTransaction.type}}</span>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          Risk Assessment
        </mat-card-title>
        <mat-card-content>
          <div class="mb-2">
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>
                <ng-container *ngIf="order.finalizedAt">PASSED</ng-container>
                <ng-container *ngIf="!order.finalizedAt && order.status === 'CANCELLED' && order.cancellationReason !== 'BUYER_CANCELLED'">REJECTED</ng-container>
                <ng-container *ngIf="!order.finalizedAt && (order.status !== 'CANCELLED' || order.cancellationReason === 'BUYER_CANCELLED')">PENDING</ng-container>
              </span>
            </div>
            <div class="labeled-box" *ngIf="order.finalizedAt">
              <div class="label">
                Accepted on
              </div>
              <span>{{ order.finalizedAt | date : 'medium' }}</span>
            </div>
            <div class="labeled-box" *ngIf="order.finalizedAt">
              <div class="label">
                Accepted by
              </div>
              <span>{{ order.finalizedBy?.displayName ?? "?" }}</span>
            </div>
          </div>
          <div class="mb-2">
            <div class="d-flex mb-2 justify-content-between align-items-center">
              <span class="mb-2" style="font-size: 12pt; font-weight: bold;">Checks</span>
            </div>
            <div class="labeled-box" *ngFor="let check of order.checks">
              <div class="label">
                {{check.key}}
              </div>
              <span>
                <a *ngIf="check.dashboardLink" [href]="check.dashboardLink" target="_blank">{{check.status}}</a>
                <ng-container *ngIf="!check.dashboardLink">
                  {{check.status}}
                </ng-container>
              </span>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer class="p-3 pt-0 d-flex justify-content-between align-items-center">
          <button mat-raised-button color="warn" (click)="reject()" [disabled]="isPerformingAction() || !order.canFinalize" [class.spinner]="isRejecting">Reject</button>
          <button mat-raised-button color="primary" (click)="finalize()" [disabled]="isPerformingAction() || !order.canFinalize" [class.spinner]="isFinalizing">Approve</button>
        </mat-card-footer>
      </mat-card>
      <mat-card class="m-2" *ngIf="order.finalizedAt">
        <mat-card-title>
          Seller Confirmation
        </mat-card-title>
        <mat-card-content>
          <div class="mb-2">
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>
                <ng-container *ngIf="order.confirmed === undefined">PENDING</ng-container>
                <ng-container *ngIf="order.confirmed !== undefined && order.confirmed === true">ACCEPTED</ng-container>
                <ng-container *ngIf="order.confirmed !== undefined && order.confirmed === false">DECLINED</ng-container>
              </span>
            </div>
            <div class="labeled-box" *ngIf="order.confirmed === undefined && order.expiration">
              <div class="label">
                Confirmation Deadline
              </div>
              <span>{{ order.expiration | date : 'medium' }}</span>
            </div>
            <div class="labeled-box" *ngIf="order.confirmedAt">
              <div class="label">
                <ng-container *ngIf="order.confirmed">Confirmed at</ng-container>
                <ng-container *ngIf="!order.confirmed">Declined at</ng-container>
              </div>
              <span>{{ order.confirmedAt | date : 'medium' }}</span>
            </div>
            <div class="labeled-box" *ngIf="order.confirmedBy">
              <div class="label">
                <ng-container *ngIf="order.confirmed">Confirmed by</ng-container>
                <ng-container *ngIf="!order.confirmed">Declined by</ng-container>
              </div>
              <span>{{ order.confirmedBy?.displayName ?? "?" }}</span>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer class="p-3 pt-0 d-flex justify-content-between align-items-center">
          <button mat-raised-button color="warn" (click)="decline()" [disabled]="isPerformingAction() || !order.canConfirm" [class.spinner]="isDeclining">Decline</button>
          <span>
            <button mat-raised-button color="primary" (click)="extendDeadline()" [disabled]="isPerformingAction() || !order.canConfirm" [class.spinner]="isExtendingDeadline">Extend Confirmation Deadline</button>
            <button mat-raised-button color="accent" class="ms-2" (click)="confirm()" [disabled]="isPerformingAction() || !order.canConfirm" [class.spinner]="isConfirming">Accept</button>
          </span>
        </mat-card-footer>
      </mat-card>
      <mat-card class="m-2" *ngIf="order.invoice.paymentType === 'WIRE'">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span class="d-flex justify-content-between align-items-center" style="gap: 10px">
              <span>Payment Details</span>
            </span>
            <span>
              <button class="leaveButton" *ngIf="order.paymentTransaction.stripeDashboardLink" (click)="onViewPaymentInStripe()" mat-stroked-button>
                View in Stripe
                <mat-icon iconPositionEnd>exit_to_app</mat-icon>
              </button>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="mb-1">
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>{{order.paymentTransaction.status}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Total
              </div>
              <span>{{(order.invoice.totalPriceCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Credits Applied
              </div>
              <span>{{order.invoice.creditsApplied}} ({{(order.invoice.creditsApplied/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}})</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Amount Due
              </div>
              <span>{{(order.invoice.paymentAmountCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
            </div>
            <div class="labeled-box" *ngIf="order.invoice.paymentAmountCents > 0">
              <div class="label">
                Payment Type
              </div>
              <span>{{order.paymentTransaction.type}}</span>
            </div>
            <div class="labeled-box" *ngIf="order.status === 'AWAITING_PAYMENT' && order.expiration">
              <div class="label">
                Payment Deadline
              </div>
              <span>{{ order.expiration | date : 'medium' }}</span>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer class="p-3 pt-0">
          <button mat-raised-button color="primary" (click)="extendDeadline()" [disabled]="isPerformingAction() || order.status !== 'AWAITING_PAYMENT'" [class.spinner]="isExtendingDeadline">Extend Payment Deadline</button>
        </mat-card-footer>
      </mat-card>
      <mat-card class="m-2" *ngIf="order.receiptShipment">
        <mat-card-title>
          Inbound Shipment
        </mat-card-title>
        <mat-card-content>
          <div class="mb-2">
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>{{order.receiptShipment.status}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Shipping Label
              </div>
              <a target='_blank' [href]="order.receiptShipment.shippingLabelUrl">Link</a>
            </div>
            <div class="labeled-box">
              <div class="label">
                Tracking Number
              </div>
              <a target='_blank' [href]="order.inboundShipmentTrackingLink">{{order.receiptShipment.fedexTrackingNumber}}</a>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer class="p-3 pt-0 d-flex flex-row" style="gap: 8px">
          <button mat-raised-button color="accent" (click)="markInboundShipmentAsInTransit()" [disabled]="isPerformingAction() || order.receiptShipment.isInTransit || order.receiptShipment.isDelivered" [class.spinner]="isMarkingInboundShipmentAsInTransit">Mark as shipped</button>
          <button mat-raised-button color="primary" (click)="markInboundShipmentAsDelivered()" [disabled]="isPerformingAction() || order.receiptShipment.isDelivered" [class.spinner]="isMarkingInboundShipmentAsDelivered">Mark as received</button>
        </mat-card-footer>
      </mat-card>
      <mat-card class="m-2" *ngIf="order.authPending || order.wasAuthenticated">
        <mat-card-title>
          Authentication & Verification
        </mat-card-title>
        <mat-card-content>
          <div class="mb-2" *ngIf="!order.verificationReport">
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span *ngIf="order.authPending">PENDING</span>
              <span *ngIf="order.status === 'CANCELLED' && order.cancellationReason === 'FAILED_AUTH'">FAILED</span>
              <span *ngIf="order.status != 'CANCELLED' && !order.authPending">PASSED</span>
            </div>
            <div class="labeled-box" *ngIf="order.wasAuthenticated">
              <div class="label">
                Verified at
              </div>
              <span>{{ order.verifiedAt | date : 'medium' }}</span>
            </div>
            <div class="labeled-box" *ngIf="order.wasAuthenticated">
              <div class="label">
                Verified by
              </div>
              <span>{{ order.verifiedBy?.displayName ?? "?" }}</span>
            </div>
          </div>
          <div class="mb-2" *ngIf="order.verificationReport">
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>
                <a [routerLink]="['/verification/verification-reports', order.verificationReport.id]">
                  {{order.verificationReport.status == 'COMPLETE' ? order.verificationReport.result : order.verificationReport.status}}
                </a>
              </span>
            </div>
            <div class="labeled-box" *ngIf="order.wasAuthenticated">
              <div class="label">
                Verified at
              </div>
              <span>{{ order.verifiedAt | date : 'medium' }}</span>
            </div>
            <div class="labeled-box" *ngIf="order.wasAuthenticated">
              <div class="label">
                Verified by
              </div>
              <span>{{ order.verifiedBy?.displayName ?? "?" }}</span>
            </div>
          </div>
          <div class="mb-2" *ngIf="order.verificationReport">
            <div class="d-flex mb-2 justify-content-between align-items-center">
              <span class="mb-2" style="font-size: 12pt; font-weight: bold;">Reports</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Intake
              </div>
              <span>
                <a [routerLink]="['/verification/intake-reports', order.verificationReport.intakeReport.id]">
                  {{order.verificationReport.intakeReport.status == 'COMPLETE' ? order.verificationReport.intakeReport.result : order.verificationReport.intakeReport.status}}
                </a>
              </span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Diagnostic
              </div>
              <span>
                <a [routerLink]="['/verification/diagnostic-reports', order.verificationReport.diagnosticReport.id]">
                  {{order.verificationReport.diagnosticReport.status == 'COMPLETE' ? order.verificationReport.diagnosticReport.result : order.verificationReport.diagnosticReport.status}}
                </a>
              </span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Authentication
              </div>
              <span>
                <a [routerLink]="['/verification/authentication-reports', order.verificationReport.authenticationReport.id]">
                  {{order.verificationReport.authenticationReport.status == 'COMPLETE' ? order.verificationReport.authenticationReport.result : order.verificationReport.authenticationReport.status}}
                </a>
              </span>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer class="p-3 pt-0 d-flex justify-content-between align-items-center">
          <button mat-raised-button color="warn" (click)="markAsInauthentic()" [disabled]="isPerformingAction()" [class.spinner]="isMarkingAsInauthentic" [disabled]="!order.authPending">Reject</button>
          <button mat-raised-button color="primary" (click)="markAsAuthentic()" [disabled]="isPerformingAction()" [class.spinner]="isMarkingAsAuthentic" [disabled]="!order.authPending">
            Mark Verified<ng-container *ngIf="order.payoutAmountCents > 0"> & Initiate Payout</ng-container>
          </button>
        </mat-card-footer>
      </mat-card>
      <mat-card class="m-2" *ngIf="order.deliveryShipment">
        <mat-card-title>
          Outbound Shipment
        </mat-card-title>
        <mat-card-content>
          <div class="mb-2">
            <div class="labeled-box">
              <div class="label">
                Status
              </div>
              <span>{{order.deliveryShipment.status}}</span>
            </div>
            <div class="labeled-box">
              <div class="label">
                Shipping Label
              </div>
              <a target='_blank' [href]="order.deliveryShipment.shippingLabelUrl">Link</a>
            </div>
            <div class="labeled-box">
              <div class="label">
                Tracking Number
              </div>
              <a target='_blank' [href]="order.outboundShipmentTrackingLink">{{order.deliveryShipment.fedexTrackingNumber}}</a>
            </div>
            <br />
            <div class="labeled-box">
              <div class="label">
                Address
              </div>
              <div class='address'>{{order.formattedDeliveryAddress}}</div>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer class="p-3 pt-0">
          <button
              mat-raised-button color="accent" class=" mt-0" (click)="markOutboundShipmentAsInTransit()" [disabled]="isPerformingAction() || order.deliveryShipment.isInTransit || order.deliveryShipment.isDelivered" [class.spinner]="isMarkingOutboundShipmentAsInTransit">Mark as shipped</button>
            <button
              mat-raised-button color="primary" class="ms-2 mt-0" (click)="markOutboundShipmentAsDelivered()" [disabled]="isPerformingAction() || order.deliveryShipment.isDelivered" [class.spinner]="isMarkingOutboundShipmentAsDelivered">Mark as delivered</button>
        </mat-card-footer>
      </mat-card>
      <mat-card class="m-2" *ngIf="order.status === 'AWAITING_SHIPMENT' && !order.deliveryShipment">
        <mat-card-title>
          Outbound Shipment
        </mat-card-title>
        <mat-card-content>
          <span class="d-flex flex-column" style="gap: 10px">
            <span class="d-flex flex-row" style="gap: 10px">
              <mat-form-field>
                <mat-label>Ship Date</mat-label>
                <input matInput [matDatepicker]="picker" [formControl]="outboundShipmentShipDate">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </span>
            <span class="d-flex flex-row" style="gap: 10px">
              <mat-form-field>
                <mat-label>Carrier</mat-label>
                <mat-select placeholder="Choose" disabled [formControl]="outboundShipmentCarrier">
                    <mat-option [value]="'FEDEX'">Fedex</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Label Format</mat-label>
                <mat-select placeholder="Choose" [formControl]="outboundShipmentLabelFormat">
                    <mat-option [value]="'PAPER_LETTER'">PAPER_LETTER</mat-option>
                    <mat-option [value]="'PAPER_4X6'">PAPER_4X6</mat-option>
                    <mat-option [value]="'STOCK_4X6'">STOCK_4X6</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="flex-fill">
                <mat-label>Special Services</mat-label>
                <mat-select placeholder="Choose" [formControl]="outboundShipmentSpecialServices" multiple>
                    <mat-option [value]="'SIGNATURE_REQUIRED'">SIGNATURE_REQUIRED</mat-option>
                    <mat-option [value]="'SATURDAY_DELIVERY'" [disabled]="outboundShipmentShipDate.value.getDay() !== 5">SATURDAY_DELIVERY</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </span>
        </mat-card-content>
        <mat-card-footer class="p-3 mt-2">
          <button mat-raised-button color="primary" (click)="generateOutboundShipment()" [disabled]="isPerformingAction()" [class.spinner]="isGeneratingOutboundShipment">Generate Shipment</button>
        </mat-card-footer>
      </mat-card>
      <div *ngIf="order.status === 'DELIVERED' && !return" class="w100 d-flex justify-content-center" style="margin-top: 40px; margin-bottom: 50px;">
        <button class="m-1" mat-raised-button color="primary" [routerLink]="['/marketplace/returns/new']" [queryParams]="{ orderId: order.id }" [disabled]="isPerformingAction()">Initiate Return</button>
      </div>
      <div *ngIf="order.status === 'DELIVERED' && return" class="w100 d-flex justify-content-center" style="margin-top: 40px; margin-bottom: 50px;">
        <button class="m-1" mat-raised-button color="primary" [routerLink]="['/marketplace/returns', return.id]" [disabled]="isPerformingAction()">
          View <ng-container *ngIf="return.status !== 'ACCEPTED'">Open </ng-container>Return
        </button>
      </div>
    </div>
    <div style="width: 100%; max-width: 400px">
      <mat-card class="m-2">
        <mat-card-title>
          Details
        </mat-card-title>
        <mat-card-content>
          <div class="labeled-box" style="width: 100%;">
            <div class="label">
              Buyer
            </div>
            <span><buyer-chip [buyer]="order.buyerProfile"></buyer-chip></span>
          </div>
          <div class="labeled-box" style="width: 100%;">
            <div class="label">
              Buyer History
            </div>
            <span>{{order.buyerProfile.totalPurchaseCount}} previous purchase{{order.buyerProfile.totalPurchaseCount == 1 ? "" : "s"}}</span>
          </div>
          <div class="labeled-box" style="width: 100%; text-align: center;">
            <div class="label">
              Delivery Address
            </div>
            <div class='address' style="width: 100%; text-align: center;">{{order.formattedDeliveryAddress}}</div>
            <button mat-button color="primary" style="position: absolute; top: 2px; right: 2px" (click)="onEditDeliveryAddressPressed()" [disabled]="!order.canEditDeliveryAddress">Edit</button>
          </div>
          <div class="labeled-box" style="width: 100%;">
            <div class="label">
              Seller
            </div>
            <span><seller-chip [seller]="order.listing.sellerProfile"></seller-chip></span>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          Items
        </mat-card-title>
        <mat-card-content>
          <div class="labeled-box" style="width: 100%; text-align: center;">
            <div class="label">
              Watch
            </div>
            <span><listing-chip [listing]="order.listing"></listing-chip></span>
          </div>
          <div class="labeled-box" style="width: 100%" *ngIf="order.invoice.bezelCareAddon()">
            <div class="label">
              Coverage
            </div>
            <span>BezelCare (1-year)</span>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          Invoice
        </mat-card-title>
        <mat-card-content>
          <table style="width: 100%">
            <tr style="width: 100%">
              <td style="width: 50%">The Watch</td>
              <td style="width: 50%; text-align: right;">{{(order.invoice.basePriceCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</td>
            </tr>
            <tr style="width: 100%" *ngIf="order.invoice.bezelCareAddon()">
              <td style="width: 50%">BezelCare</td>
              <td style="width: 50%; text-align: right;">
                {{(order.invoice.bezelCareAddon().priceCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}
              </td>
            </tr>
            <tr style="width: 100%">
              <td style="width: 50%">Insured Shipping</td>
              <td style="width: 50%; text-align: right;">{{order.invoice.shippingCostCents > 0 ? (order.invoice.shippingCostCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"}) : 'Free'}}</td>
            </tr>
            <tr style="width: 100%">
              <td style="width: 50%; color: #1C7D67;">Bezel Authentication Report</td>
              <td style="width: 50%; text-align: right; color: #1C7D67;">Free</td>
            </tr>
            <tr style="width: 100%" *ngIf="order.invoice.discountAmountCents > 0">
              <td style="width: 50%">Discount ({{order.invoice.discountCode}})</td>
              <td style="width: 50%; text-align: right;">-{{(order.invoice.discountAmountCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</td>
            </tr>
            <tr style="width: 100%" *ngIf="order.invoice.buyersPremiumCents > 0">
              <td style="width: 50%">Auction Fee</td>
              <td style="width: 50%; text-align: right;">{{(order.invoice.buyersPremiumCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</td>
            </tr>
            <tr style="width: 100%" *ngIf="order.invoice.paymentProcessingFeeCents > 0">
              <td style="width: 50%">Payment Processing Fee</td>
              <td style="width: 50%; text-align: right;">{{(order.invoice.paymentProcessingFeeCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</td>
            </tr>
            <tr style="width: 100%">
              <td style="width: 50%">Sales Tax</td>
              <td style="width: 50%; text-align: right;">{{order.invoice.taxCostCents > 0 ? (order.invoice.taxCostCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"}) : 'Free'}}</td>
            </tr>
            <tr style="width: 100%" *ngIf="order.invoice.creditsApplied > 0">
              <td style="width: 50%">Bezel Credit</td>
              <td style="width: 50%; text-align: right;">-{{(order.invoice.creditsApplied/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</td>
            </tr>
            <br />
            <tr style="width: 100%">
              <td style="width: 50%; font-weight: bold;">Total</td>
              <td style="width: 50%; text-align: right; font-weight: bold;">{{(order.invoice.paymentAmountCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span>Payout</span>
            <span>
              <button class="ms-1" *ngIf="!isEditingPayoutInfo" mat-stroked-button color="primary" (click)="startEditingPayoutInfo()" [disabled]="!order.canEditPayoutAmount">Edit</button>
              <button class="ms-1" *ngIf="isEditingPayoutInfo" mat-stroked-button color="warn" [disabled]="isPerformingAction()" (click)="cancelEditingPayoutInfo()">Cancel</button>
              <button class="ms-1" *ngIf="isEditingPayoutInfo" mat-stroked-button color="primary" [disabled]="isPerformingAction() || (!commissionRate.dirty && !sellerFee.dirty && !minTotalFee.dirty)" [class.spinner]="isSavingPayoutInfo" (click)="savePayoutInfoEdits()">Save</button>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content>
          <table style="width: 100%">
            <tr style="width: 100%">
              <td style="width: 50%">Sale</td>
              <td style="width: 50%; text-align: right;">{{(order.salePriceCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</td>
            </tr>
            <tr style="width: 100%">
              <td style="width: 50%">
                <span *ngIf="!isEditingPayoutInfo">
                  <ng-container *ngIf="!order.hitMinimumComission">Commission ({{order.commissionRateBips / 100 | number}}%)</ng-container>
                  <ng-container *ngIf="order.hitMinimumComission">Minimum Commission</ng-container>
                </span>
                <mat-form-field appearance="outline" *ngIf="isEditingPayoutInfo" class="pt-2">
                  <mat-label>Commission (%)</mat-label>
                  <input matInput placeholder="25.00" [formControl]="commissionRate">
                </mat-form-field>
              </td>
              <td style="width: 50%; text-align: right;">
                <span *ngIf="!isEditingPayoutInfo">
                  <ng-container *ngIf="!order.hitMinimumComission">-{{(order.salePriceCents * order.commissionRateBips / 1000000).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</ng-container>
                  <ng-container *ngIf="order.hitMinimumComission">-{{((order.salePriceCents - order.payoutAmountCents) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</ng-container>
                </span>
                <span *ngIf="isEditingPayoutInfo">-{{(order.salePriceCents * (commissionRate.value / 100) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
              </td>
            </tr>
            <tr style="width: 100%">
              <td style="width: 50%">
                <span *ngIf="!isEditingPayoutInfo">Seller Protection</span>
                <mat-form-field appearance="outline" *ngIf="isEditingPayoutInfo" class="pt-2">
                  <mat-label>Seller Protection ($)</mat-label>
                  <input matInput placeholder="25.00" [formControl]="sellerFee">
                </mat-form-field>
              </td>
              <td style="width: 50%; text-align: right;">
                <span *ngIf="!isEditingPayoutInfo">
                  <ng-container *ngIf="!order.hitMinimumComission">-{{(order.sellerFeeCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</ng-container>
                  <ng-container *ngIf="order.hitMinimumComission">Included</ng-container>
                </span>
                <span *ngIf="isEditingPayoutInfo">-{{((sellerFee.value * 100) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
              </td>
            </tr>
            <tr style="width: 100%" *ngIf="isEditingPayoutInfo">
              <td style="width: 50%">
                <mat-form-field appearance="outline" class="pt-2">
                  <mat-label>Minimum Fee ($)</mat-label>
                  <input matInput placeholder="175.00" [formControl]="minTotalFee">
                </mat-form-field>
              </td>
              <td style="width: 50%; text-align: right;">
                <span>-{{ Math.max(minTotalFee.value - (order.salePriceCents * (commissionRate.value / 100) / 100) - sellerFee.value, 0).toLocaleString("en-US", {style:"currency", currency:"USD"}) }}</span>
              </td>
            </tr>
            <br />
            <tr style="width: 100%">
              <td style="width: 50%; font-weight: bold;">Total</td>
              <td style="width: 50%; text-align: right; font-weight: bold;">
                <span *ngIf="!isEditingPayoutInfo">{{(order.payoutAmountCents/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}}</span>
                <span *ngIf="isEditingPayoutInfo">{{ ((order.salePriceCents - Math.max((order.salePriceCents * commissionRate.value / 100) - (sellerFee.value * 100), minTotalFee.value * 100)) / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) }}</span>
              </td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
      <mat-card class="m-2">
        <mat-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span>Notes</span>
            <span>
              <button class="m-1" *ngIf="!isEditingNotes" mat-stroked-button color="primary" (click)="startEditingNotes()">Edit</button>
              <button class="m-1" *ngIf="isEditingNotes" mat-stroked-button color="warn" [disabled]="isPerformingAction()" (click)="cancelEditingNotes()">Cancel</button>
              <button class="m-1" *ngIf="isEditingNotes" mat-stroked-button color="primary" [disabled]="isPerformingAction() || !internalNotes.dirty" [class.spinner]="isSavingNotes" (click)="saveNotesEdits()">Save</button>
            </span>
          </div>
        </mat-card-title>
        <mat-card-content style="max-height: 340px; overflow-y: scroll">
          <ng-container *ngIf="!isEditingNotes"><span style="white-space:pre-wrap;">{{order.internalNotes}}</span></ng-container>
          <mat-form-field appearance="outline" class="w-100" *ngIf="isEditingNotes">
            <textarea matInput [formControl]="internalNotes" [maxLength]="1024" style="min-height: 160px;"></textarea>
            <mat-hint align="end">{{internalNotes.value?.length || 0}}/1024</mat-hint>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="d-flex flex-column align-items-center m-5">
    <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
  </div>
</ng-template>
