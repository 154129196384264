import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UsersService } from 'src/app/_services/users.service';

@Component({
  selector: 'app-confirm-delete-user',
  templateUrl: './confirm-delete-user.component.html',
  styleUrls: ['./confirm-delete-user.component.css']
})
export class ConfirmDeleteUserComponent implements OnInit {
  errorMsg: string | null = null;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDeleteUserComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {userId: string},
    private usersService: UsersService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  onDelete(): void {
    this.usersService.deleteUser(this.data.userId).subscribe(
      {
        next: ({userId}) => {
          this.errorMsg = null;

          this.closeDialog({
            deletedUserId: userId
          });
        },
        error: (response) => {
          this.errorMsg = response.error.error;
          console.log(`Failed to delete user: ${this.errorMsg}`);
        }
      }
    );
  }

  onCancel(): void {
    this.closeDialog({});
  }

  closeDialog(result: {
    deletedUserId?: string
  }) {
    this.dialogRef.close(result);
  }
}
